<template>
<!-- main button in planner -->
  <button
    type="button"
    class="btn btn-outline-dark d-flex flex-column align-items-center"
    data-bs-toggle="modal"
    data-bs-target="#settingsModal"
    title="Settings"
  >
    <i class="bi bi-gear"></i>
    <span>settings</span>
  </button>
  <!-- modal window -->
  <div
    class="modal fade"
    id="settingsModal"
    tabindex="-10"
    aria-labelledby="settingsModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" id="mc2">
        <div class="modal-header">
          <div>
            <h5 class="modal-title" id="settingsModalLabel">
              <i class="bi bi-gear"></i> Settings
            </h5>
          </div>
          <div class="btn-group ms-auto" role="group" aria-label="Button group">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <hr class="hr" />
        </div>
        <div class="modal-body">
          <!-- settings row main planner settings -->
          <div class="row my-group my-2">
            <div class="col-3">
              <div class="mb-3">
                <label for="layoutWidth" class="form-label">Layout width</label>
                <input
                  id="layoutWidth"
                  class="form-control my-input"
                  type="number"
                  v-model="this.layoutSettings.width"
                  :step="layoutSettings.gridSize"
                />
              </div>
            </div>

            <div class="col-3">
              <div class="mb-3">
                <label for="layoutHeight" class="form-label"
                  >Layout height</label
                >
                <input
                  id="layoutHeight"
                  class="form-control my-input"
                  type="number"
                  v-model="this.layoutSettings.height"
                  :step="layoutSettings.gridSize"
                />
              </div>
            </div>
           
          </div>
          <!-- settings row  grid and selection-->
          <div class="row my-group my-2">
            <div class="col-2">
              <div class="mb-3">
                <label for="gridSize" class="form-label">Size of grid</label>
                <input
                  id="gridSize"
                  class="form-control my-input"
                  type="number"
                  v-model="this.layoutSettings.gridSize"
                  step="1"
                />
              </div>
            </div>
            <div class="col-2">
              <label for="offset" class="form-label">Layout offset</label>
              <input
                id="offset"
                class="form-control my-input"
                type="number"
                v-model="this.layoutSettings.canvasOffset"
                step="1"
              />
            </div>
            <div class="col-2">
              <label for="offset" class="form-label">Selection color</label>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownLayoutSettings"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  :style="{ backgroundColor: layoutSettings.selectionColor }"
                >
                  Select Color
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownLayoutSettings">
                  <li v-for="(value, key) in colors" :key="key">
                    <a
                      class="dropdown-item"
                      @click="selectColor(value)"
                      :style="{ backgroundColor: value }"
                      >{{ key }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- settings row metadata-->
          <div class="row my-group my-2">
            <div class="col-2">
              <div class="mb-3">
                <label for="layoutTitle" class="form-label">Layout title:</label>
                <input
                  id="layoutTitle"
                  class="form-control my-input"
                  type="text"
                  v-model="this.layoutSettings.layoutMetaData.title"
                  step="1"
                />
              </div>
            </div>
            <div class="col-2">
              <label for="author" class="form-label">Author:</label>
              <input
                id="author"
                class="form-control my-input"
                type="text"
                v-model="this.layoutSettings.layoutMetaData.author"
                step="1"
              />
            </div>
             <div class="col-2">
              <label for="email" class="form-label">E-mail:</label>
              <input
                id="email"
                class="form-control my-input"
                type="text"
                v-model="this.layoutSettings.layoutMetaData.email"
                step="1"
              />
            </div>
             <div class="col-2">
              <label for="info" class="form-label">Additional information:</label>
              <input
                id="info"
                class="form-control my-input"
                type="text"
                v-model="this.layoutSettings.layoutMetaData.comments"
                step="1"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" data-bs-dismiss="modal" @click="save">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/eventBus';
import bootstrapServices from '@/components/Planner/Services/botstrapServices';
export default {
  name: "LayoutSettings",
  data() {
    return {
      colors: {
        "Midnight Blue": "#191970",
        Teal: "#008080",
        Coral: "#FF7F50",
        "Slate Gray": "#708090",
        Olive: "#808000",
        Lavender: "#E6E6FA",
        Goldenrod: "#DAA520",
        Aquamarine: "#7FFFD4",
        Maroon: "#800000",
        Periwinkle: "#CCCCFF",
        Peach: "#FFDAB9",
        Plum: "#DDA0DD",
        Orchid: "#DA70D6",
        Turquoise: "#40E0D0",
        Salmon: "#FA8072",
      },
    };
  },
  methods: {
    getLayoutWidthLable() {
      if (this.layoutSettings.imperialUnits) {
        return (
          parseFloat(
            (this.layoutSettings.width / this.layoutSettings.mmToFt).toFixed(2)
          ) + "ft"
        );
      } else {
        return this.layoutSettings.width + "mm";
      }
    },
    getLayoutHeightLable() {
      if (this.layoutSettings.imperialUnits) {
        return (
          parseFloat(
            (this.layoutSettings.height / this.layoutSettings.mmToFt).toFixed(2)
          ) + "ft"
        );
      } else {
        return this.layoutSettings.height + "mm";
      }
    },
    selectColor(color) {
      this.layoutSettings.selectionColor = color;
    },
    save() {
      console.debug("saving settings",this.layoutSettings);
      this.$store.commit('setSettings',this.layoutSettings)
    },
     showModal(){
      bootstrapServices.methods.openModal("settingsModal")}
  },
  computed:{
    layoutSettings(){
      return this.$store.getters.layoutSettings
    }
  },
  mounted(){
    EventBus.on('openSettings', this.showModal);
  }
};
</script>

<style>
.modal-backdrop {
  background-color: transparent !important;
}

.modal-content {
  /* background-color: transparent !important; */
  border: 1px solid black !important;
  box-shadow: 30px 20px 20px rgb(166, 166, 166);
  backdrop-filter: blur(16px);
}
.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.modal-header {
  border: 1px solid black !important;
}

.large-switch .form-check-input {
  width: 50px;
  height: 25px;
}
.my-group {
  border: 1px solid rgb(79, 77, 77);
  border-radius: 16px;
}
.my-input {
  background: transparent !important;
  border: 1px solid rgb(202, 200, 200) !important;
  border-radius: 10px !important;
}
.dropdown-menu a {
  display: flex;
  align-items: center;
}

.dropdown-menu a::before {
  content: "";
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
}
</style>