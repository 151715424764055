<template>
  <Planner v-if="loaded"></Planner>
  <spiner v-if="isLoading"/>
</template>

<script>

import Planner from "@/components/Planner/views/Planner.vue"
import spiner from "@/components/helpers/SpinnerSimple.vue";


export default {
  name: 'App',
  components: {
    Planner,
    spiner
  },
  data(){return{
    loaded:false
  }},
  computed:{
    isLoading(){
      return this.$store.getters.isLoading;
    }
  },
  async mounted(){
    await this.$store.dispatch('fetchProducts');
    this.loaded = true
    document.title = "Optical Layout Planner";
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
@keyframes positiveAnimation {
  0% {
    background-color: initial;
  }
  50% {
    background-color: darkseagreen;
  }
  100% {
    background-color: initial;
  }
}

@keyframes negativeAnimation {
  0% {
    background-color: initial;
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: initial;
  }
}



body{
  font-family: Helvetica;
}

.form-control{
  background: white !important;
}

.disabled-button {
  opacity: 0;
  cursor: not-allowed;
  color: white;
}
.disabled-button .bi {
  color: rgb(222, 216, 216);
}
button:hover {
    /* Your hover styles here */
    background-color: rgb(228, 226, 226) !important; /* Example: Change background color to red */
    color: #0b03ff !important; /* Example: Change text color to white */
    /* box-shadow: 5px 6px 6px rgba(55, 55, 55, 0.3) */
}
</style>
