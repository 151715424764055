<template>
  <button
    :disabled="!canvasElementSelected"
    :class="{ 'disabled-button': !canvasElementSelected }"
    class="btn btn-outline-dark  d-flex flex-column align-items-center"
    title="Log canvas"
    data-bs-toggle="offcanvas"
    data-bs-target="#selectionOffCanvasElement"
    aria-controls="offcanvasWithBackdrop"
  >
    <i class="bi bi-tools"></i>
    <span>tools</span>
  </button>

  <div
    class="offcanvas offcanvas-end"
    tabindex="-1"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    id="selectionOffCanvasElement"
    aria-labelledby="offcanvasWithBackdropLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">
        Selected element: {{
          selected
            ? selected.type === "activeSelection"
              ? "selected " + selected._objects.length + " objects"
              : selected.name
            : "nothing selected"
        }}
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <general-settings-vue
      :layout="layout" 
      :services="services"
      ref="generalSettings"
      />
      <div v-show="showBB">
        <black-box-settings
        :layout="layout" 
        :services="services"
        ref="blackBoxSettings"
        @update:blackBox="resizeBlackBox"/>
      </div>
      <div v-show="showLS">
        <LineSettingsVue
        :layout="layout"
        ref="lineSettings"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric";
import BlackBoxSettings from "@/components/Planner/SelectionSettings/components/BlackBoxSettings.vue"
import GeneralSettingsVue from './components/GeneralSettings.vue';
import LineSettingsVue from './components/LineSettings.vue';

export default {
  props: {
    layout: Object,
    services: Object,
  },
  components: {
    BlackBoxSettings,
    GeneralSettingsVue,
    LineSettingsVue
  },
  data() {
    return {
      activeColor: "",
    };
  },
  computed: {
    showBB() {
      return this.selected?.name === "BLACK-BOX";
    },
    showLS() {
      return this.selected?.type === "line";
    },
    canvasElementSelected() {
      //checking proxy is empty ...
      return this.selected instanceof fabric.Object;
    },
    selected() {
      return this.$store.getters.selection;
    },
    selectedColor() {
      console.info(
        "selectedColor: ",
        this.layout.getActiveObject()?.getObjects()[0]?.fill || ""
      );
      return this.layout.getActiveObject()?.getObjects()[0]?.fill || "";
    },
  },
  methods: {
    resizeBlackBox(blackBox){
      this.$emit("update:blackBox",blackBox)
    },
     updateBlackBoxColor(color, applyToAll) {
      this.services.layoutServices.updateBlackBoxColor(
        color,
        1,
        applyToAll,
        this.layout
      );
    },
    updateSizes(scaling) {
      if (this.selected?.name !== "BLACK-BOX") {
       return
      }
      this.$refs?.blackBoxSettings?.updateSizes(scaling)
    },
  },
  mounted() {
    var offcanvasElement = document.getElementById("selectionOffCanvasElement");
    offcanvasElement.addEventListener("show.bs.offcanvas", () => { });
    offcanvasElement.addEventListener("shown.bs.offcanvas", () => {
      this.$refs.generalSettings.enableMovingListener()
    });
    offcanvasElement.addEventListener("hide.bs.offcanvas", () => { });
    offcanvasElement.addEventListener("hidden.bs.offcanvas", () => {
      this.$refs.generalSettings.disableMovingListener() });
  },
  watch: {
    selected() {
    },
  },
};
</script>

<style>
.offcanvas {
  box-shadow: -30px 30px 120px #a6a6a6;
  backdrop-filter: blur(4px);
  width: 25% !important;
}

.btn-close {
  border: 1px solid red;
}
/* input {
  height: 27px !important;
} */
</style>