<template>
  <div
    class="btn-group me-2"
    role="group"
    aria-label="Decorations"
  >
    <button
      class="btn btn-outline-dark d-flex flex-column align-items-center"
      :class="{ active: drawing.showColors }"
      @click="drawLine()"
      title="Draw polygon line, RightClick/[Esc] to terminate, [Shift] for right angles"
    >
      <i
        class="bi bi-pencil-fill"
        :style="{ color: drawing.selectedColor }"
      ></i>
      <span>line</span>
    </button>

    <!-- REBIND -->

    <ColorSelector
      v-if="drawing.showColors"
      ref="anotationColorSelector"
      @update:selectedColor="drawLine"
    />

    <button
      class="btn btn-outline-dark d-flex flex-column align-items-center"
      :class="{ active: this.layoutSettings.mode === 'measuring' }"
      title="Measure distance"
      @click="measure()"
    >
      <i class="bi bi-rulers"></i>
      <span>ruller</span>
    </button>
    <button
      class="btn btn-outline-dark d-flex flex-column align-items-center"
      @click="
        this.services.layoutServices.annotate(
          this.layoutSettings,
          this.$refs.anotationColorSelector.selectedColor,
          layout
        )
      "
    >
      <i class="bi bi-textarea-t"></i>
      <span>annotate</span>
    </button>
    <ColorSelector
      ref="anotationColorSelector"
      @update:selectedColor="updateAnotationColor"
    />
  </div>
</template>

<script>
import { EventBus } from '@/eventBus';
import ColorSelector from "@/components/helpers/ColorSelector.vue";
export default {
  props: {
    services: {
      type: Object,
      default: () => ({}),
    },
    layout: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    ColorSelector,
  },
  data() {
    return {
      drawing: {
        showColors: false,
        colors: ["#c0392b", "#152fb6", "#056b29", "#e0d52b", "#000000"],
        selectedColor: "#c0392b",
      },
    };
  },
  computed: {
    layoutSettings() {
      return this.$store.state.layoutSettings;
    },
  },
  methods: {
    updateAnotationColor(color, applyToAll) {
      this.services.layoutServices.updateAnotationColor(
        color,
        applyToAll,
        this.layout
      );
    },
    measure() {
      switch (this.layoutSettings.mode) {
        case "measuring":
          this.layoutSettings.mode = "none";
          this.services.layoutServices.enableSelection(this.layout);
          console.info("%c-measuring mode disabled", "color: darkseagreen;");
          break;
        default:
          this.layoutSettings.mode = "measuring";
          this.services.layoutServices.disableSelection(this.layout);
          this.services.measuringServices.enableListeners();
          console.info("%c-measuring mode enabled", "color: darkseagreen;");
      }
    },
    drawLine(color) {
      if (color !== undefined) {
        this.drawing.selectedColor = color;
      }
      if (this.layoutSettings.mode !== "drawing" && color == undefined) {
        this.services.lineServices.enableListeners(this.layout);
        this.services.layoutServices.disableSelection(this.layout);
        this.layoutSettings.mode = "drawing";
        console.info("%c-drawing mode enabled", "color: darkseagreen;");
      } else if (this.layoutSettings.mode === "drawing" && color == undefined) {
        this.layoutSettings.mode = "none";
        //this.services.layoutServices.enableSelection(this.layout);
        console.info("%c-drawing mode disabled", "color: darkseagreen;");
      } else if (this.layoutSettings.mode === "drawing" && color != undefined) {
        this.services.lineServices.setDrawingColor(color);
        console.info("%c-color switched to " + color, "color: darkseagreen;");
      }
    },
  },
  watch: {
    "layoutSettings.mode": function (newVal) {
      console.info(
        "%cplaner mode switched to " + this.layoutSettings.mode,
        "color: darkseagreen;"
      );
      this.drawing.showColors = newVal === "drawing";
      if (newVal === "none") {
        this.services.layoutServices.enableSelection(this.layout);
      }
    },
  },
  mounted(){
    EventBus.on('draw', ()=>{this.drawLine(this.color)});
    EventBus.on('ruller', ()=>{this.measure()});
    EventBus.on('text', ()=>{this.services.layoutServices.annotate(
          this.layoutSettings,
          this.$refs.anotationColorSelector.selectedColor,
          this.layout
        )});
  }
};
</script>

<style>
</style>