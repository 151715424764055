export default {
    methods: {
        openModal(name) {
            const modalElement = document.getElementById(name);
            // eslint-disable-next-line no-undef
            const modalInstance = new bootstrap.Modal(modalElement);
            modalInstance.show();
        },
        closeModal() {
            const modalElement = document.getElementById('modal_demo');
            // eslint-disable-next-line no-undef
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
    }
}