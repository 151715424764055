<template>
  <div class="container my-3">
    <div class="title">
      <h5>General settings</h5>
      <hr />
    </div>
    <div class="row align-items-start">
      <label>Position</label>
      <div class="row">
        <label for="" class="col-sm-1 col-form-label">Left</label>
        <div class="col-sm-4">
          <input
            type="number"
            step="1"
            class="form-control border-dark"
            id=""
            v-model="position.left"
            @input="setPosition()"
          />
        </div>
        <label for="" class="col-sm-1 col-form-label">Top</label>
        <div class="col-sm-4">
          <input
            type="number"
            step="1"
            class="form-control border-dark"
            id=""
            v-model="position.top"
            @input="setPosition()"
          />
        </div>
      </div>
    </div>
    <hr />
    <div class="my-2">
      <ProductManipulatorVue :layout="layout"/>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric";
import ProductManipulatorVue from "../../views/ProductManipulator.vue";
export default {
  components: {
    ProductManipulatorVue,
  },
  props: {
    layout: Object,
    services: Object,
  },
  data() {
    return {
      position: {
        left: "",
        top: "",
      },
    };
  },
  computed: {
    selected() {
      return this.$store.getters.selection;
    },
    canvasElementSelected() {
      return this.selected instanceof fabric.Object;
    },
  },
  methods: {
    setPosition() {
      this.selected.set({
        left: this.position.left,
        top: this.position.top,
      });
      this.layout.requestRenderAll();
    },
    enableMovingListener() {
      this.layout.on("object:moving", this.movingListener.bind(this));
      console.debug("moving listener enabled");
    },
    disableMovingListener() {
      this.layout.off("object:moving", this.movingListener.bind(this));
      console.debug("moving listener disabled");
    },
    movingListener(event) {
      this.position.left = event.target?.left;
      this.position.top = event.target?.top;
    },
  },
  watch: {
    selected(newVal) {
      if (this.canvasElementSelected) {
        this.position.left = newVal.left;
        this.position.top = newVal.top;
      } else {
        this.position.left = "";
        this.position.top = "";
      }
    },
  },
};
</script>

<style>
</style>