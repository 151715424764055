<template>
  <button
    id="3dButton"
    type="button"
    class="btn btn-md btn-outline-dark"
    :class="{ 'disabled-button': !selectedProduct?.is3DAvailable }"
    data-bs-toggle="modal"
    data-bs-target="#Modal3D"
    @click="showProduct()"
    title="Show product 3d model"
    :disabled="!selectedProduct?.is3DAvailable"
  >
    <i class="bi bi-badge-3d"></i>
  </button>

  <div
    class="modal fade"
    id="Modal3D"
    tabindex="-1"
    aria-labelledby="Modal3DLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content" id="mc3d">
        <div class="modal-header">
          <h5 class="modal-title" id="Modal3DLabel">
            {{ selectedProduct?.longName }}
          </h5>
          <div class="btn-group" role="group" aria-label="Button group">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="onClose"
            >
              Close
            </button>
            <button
              id="download-button"
              type="button"
              class="btn btn-primary"
              :disabled="!selectedProduct?.isStpAvailable"
              @click="downloadStp"
            >
              Download stp
            </button>
          </div>
        </div>
        <div class="modal-body" id="md">
          <spiner ref="spinner" v-if="loading" />
          <!-- <div class="loading-indicator" v-if="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Kraunama...</span>
    </div>
  </div> -->
          <div ref="renderer"></div>

          <div class="disclaimer">
            <span
              >The STEP files are provided for reference purposes and may not
              precisely represent product dimensions. For accurate information
              specific to a product model, please contact our sales team. Note
              that updates may occur without prior notice.</span
            >
            <!-- <button type="button" class="btn-close" aria-label="Close"></button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader.js";
import spiner from "@/components/helpers/Spinner.vue";

export default {
  name: "OBJViewer",
  components: { spiner },
  data() {
    return {
      modelsUrl: process.env.VUE_APP_3D_PATH,
      available: false,
      stpAvailable: false,
      loadingProgress: 0,
      loading: false,
      width: 0,
      height: 0,
      mode: {
        light: {
          background: {
            color: 0x000000,
            opacity: 0.0,
          },
          ground: {
            color: 0x000000,
            opacity: 0.0,
          },
        },
        dark: {
          background: {
            color: 0x000000,
            opacity: 0.7,
          },
          ground: {
            color: 0xcccccc,
            opacity: 0.1,
          },
        },
      },
    };
  },
  computed: {
    selectedProduct() {
      return this.$store.getters.selectedProduct;
    },
  },
  // mounted() {
  //   const closeBtn = document.querySelector(".disclaimer .btn-close");
  //   const disclaimer = document.querySelector(".disclaimer");
  //   closeBtn.addEventListener("click", function () {
  //     disclaimer.style.display = "none"; // arba naudoti `disclaimer.remove();` jei norite visiškai pašalinti iš DOM
  //   });
  // },
  methods: {
    downloadStp() {
      fetch(
        `${process.env.VUE_APP_3D_PATH}OBJ/${this.selectedProduct.name}/${this.selectedProduct.name}.stp`
      )
        .then((response) => response.blob())
        .then((blob) => {
          const downloadUrl = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.style.display = "none";
          a.href = downloadUrl;
          a.download = this.selectedProduct.name + ".stp";

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          window.URL.revokeObjectURL(downloadUrl);
        })
        .catch((error) => console.error("Klaida atsisiunčiant failą:", error));
    },
    onClose() {
      try {
        this.renderer.dispose();
      } catch (error) {
        console.debug("render not created");
      }
    },
    showProduct() {
      this.initAll();
    },
    initAll() {
      var modalElement = document.getElementById("Modal3D");
      modalElement.addEventListener("shown.bs.modal", () => {
        this.width = document.getElementById("mc3d").clientWidth;
        this.height = document.getElementById("mc3d").clientWidth;
        console.debug("window: ", this.width, this.height);
        this.renderer.setSize(
          document.getElementById("mc3d").clientWidth,
          document.getElementById("mc3d").clientWidth
        );
        this.camera.aspect =
          document.getElementById("mc3d").clientWidth /
          document.getElementById("mc3d").clientWidth;
        this.camera.updateProjectionMatrix();
      });
      this.initScene(this.width, this.height);
      this.loadOBJ();
      this.enableRenderResizeListener();
    },
    enableRenderResizeListener() {
      window.addEventListener("resize", () => {
        this.renderer.setSize(
          document.getElementById("mc3d").clientWidth,
          document.getElementById("mc3d").clientWidth
        );
        this.camera.aspect =
          document.getElementById("mc3d").clientWidth /
          document.getElementById("mc3d").clientWidth;
        this.camera.updateProjectionMatrix();
      });
    },
    centerObject(object) {
      const box = new THREE.Box3().setFromObject(object);

      const center = box.getCenter(new THREE.Vector3());
      center.y = box.min.y;
      object.position.sub(center);
    },

    initScene(width, height) {
      console.debug("initiating scene: ");
      this.scene = new THREE.Scene();
      this.scene.background = null;
      this.camera = new THREE.PerspectiveCamera(760, width / height, 1, 100000);

      this.camera.position.z = 1000;
      this.camera.position.x = -1000;
      this.camera.position.y = 500;

      this.camera.lookAt(0, 0, 0);

      const ambientLight = new THREE.AmbientLight(0xffffff, 1.01);
      this.scene.add(ambientLight);

      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(1, 1, 1);
      this.scene.add(light);

      //this.scene.add(new THREE.AxesHelper(500));

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(width, height);
      this.renderer.setClearColor(0x000000, 0.0);

      const stlViewerContainer = this.$refs.renderer;

      const existingCanvas = stlViewerContainer?.querySelector("canvas");

      if (existingCanvas) {
        existingCanvas.remove();
        console.debug("old canvas removed");
      }

      this.$refs.renderer.appendChild(this.renderer.domElement);

      const light3 = new THREE.SpotLight(0xffffff, 1000000);
      light3.position.set(-500, 500, 500);

      this.scene.add(light3);
      //this.scene.add(new THREE.SpotLightHelper(light3, 0x7777ff));

      var controlsCamera = new OrbitControls(
        this.camera,
        this.renderer.domElement
      );
      controlsCamera.enableDamping = true;
      controlsCamera.rotateSpeed = 1.05;
      controlsCamera.dampingFactor = 0.1;
      controlsCamera.enableZoom = true;
      controlsCamera.enablePan = true;
      controlsCamera.autoRotate = true;
      controlsCamera.autoRotateSpeed = 0.15;
      var controlsLight = new OrbitControls(light3, this.renderer.domElement);

      controlsLight.autoRotate = true;
      controlsLight.autoRotateSpeed = 3.5;

      const animate = () => {
        requestAnimationFrame(animate);
        controlsCamera.update();
        controlsLight.update();
        this.renderer.render(this.scene, this.camera);
      };
      animate();

      const circleGeometry = new THREE.CircleGeometry(500, 32);

      const circleMaterial = new THREE.MeshBasicMaterial({
        map: this.createGradientTexture(),
        color: 0x999999,
        transparent: true,
        opacity: 0.9,
      });

      const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);

      circleMesh.rotation.x = -Math.PI / 2;

      this.scene.add(circleMesh);
    },

    createGradientTexture() {
      const canvas = document.createElement("canvas");
      canvas.width = 256;
      canvas.height = 256;
      const context = canvas.getContext("2d");

      const gradient = context.createRadialGradient(
        canvas.width / 2,
        canvas.height / 2,
        100, // pradinis spindulys
        canvas.width / 2,
        canvas.height / 2,
        canvas.width / 2 // galinis spindulys
      );

      gradient.addColorStop(0, "rgba(255,255,255,1)");
      gradient.addColorStop(1, "rgba(255,255,255,0)");

      context.fillStyle = gradient;
      context.fillRect(0, 0, canvas.width, canvas.height);

      return new THREE.CanvasTexture(canvas);
    },

    loadOBJ() {
      this.loading = true;

      const mtlLoader = new MTLLoader();
      mtlLoader.load(
        `${process.env.VUE_APP_3D_PATH}OBJ/${this.selectedProduct.name}/${this.selectedProduct.name}.mtl`,
        (materials) => {
          materials.preload();

          const objLoader = new OBJLoader();
          objLoader.setMaterials(materials);
          objLoader.load(
            `${process.env.VUE_APP_3D_PATH}OBJ/${this.selectedProduct.name}/${this.selectedProduct.name}.obj`,
            (object) => {
              this.centerObject(object);
              this.scene.add(object);
              this.loading = false; // išjungti spinnerį
            },
            (xhr) => {
              this.$refs.spinner.setProgress(
                Math.round((xhr.loaded / xhr.total) * 100)
              );
            },
            (error) => {
              console.error("An error happened: ", error);
              this.loading = false; // išjungti spinnerį esant klaidai
            }
          );
        },
        (xhr) => {
          console.debug((xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        (error) => {
          console.error("An error happened: ", error);
          this.loading = false; // išjungti spinnerį esant klaidai
        }
      );
    },
    animatePositive(element) {
      element.style.animation = "none";

      setTimeout(() => {
        element.style.animation = "positiveAnimation 2s";
      }, 50);
    },
    animateNegative(element) {
      element.style.animation = "none";

      setTimeout(() => {
        element.style.animation = "negativeAnimation 2s";
      }, 50);
    },
  },
  beforeUnmount() {
    this.onClose();
  },
};
</script>

<style>
.btn-md{
height: 63px;
}
.modal-backdrop {
  background-color: transparent !important;
}

#mc3d {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 1px solid black !important;
  box-shadow: 30px 20px 20px rgb(166, 166, 166);
  backdrop-filter: blur(16px);
}
.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.modal-header {
  border: 1px solid black !important;
}
.disclaimer {
  width:90%;
  border: 1px dashed red;
  color: red;
  position: absolute;
  left: 5%;
  top: 0%;
}
</style>