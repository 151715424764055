import { fabric } from "fabric";
//Singleton
export class LineServices {
    static instance;
    constructor(layoutSettings, layout) {
        if(LineServices.instance){
            return LineServices.instance
        }
        this.layoutSettings = layoutSettings;
        this.drawingColor = "#c0392b";
        this.layout = layout
        this.listeners = {
            context: this.contextListener.bind(this),
            keyUp: this.keyUpListener.bind(this),
            keyDown: this.keyDownListener.bind(this),
            mouseDown: this.mouseDownListener.bind(this),
            mouseMove: this.mouseMoveListener.bind(this)
        }
        LineServices.instance = this
        return LineServices.instance
    }
    static getInstance() {
        if (!LineServices.instance) {
            LineServices.instance = new LineServices();
        }
        return LineServices.instance;
    }

    contextListener(e) {
        e.preventDefault();
        this.terminate(this.layout, this.layoutSettings)
    }
    mouseMoveListener(options) {
        if (this.layoutSettings.mode === "drawing") {
            this.mouseMove(options, this.layout);
        }
    }
    mouseDownListener(options) {
        if (this.layoutSettings.mode === "drawing")
            this.drawLine(options, this.layout);
    }
    keyDownListener(options) {
        var key = options.which || options.keyCode;
        switch (key) {
            case 16:
                this.layoutSettings.keysPressed.shift = true;
                break;
            case 17:
                this.layoutSettings.keysPressed.ctrl = true;
                break;
        }
    }
    keyUpListener(options) {
        var key = options.which || options.keyCode;
        switch (key) {
            case 16:
                this.layoutSettings.keysPressed.shift = false;
                break;
            case 17:
                this.layoutSettings.keysPressed.ctrl = false;
                break;
        }
    }
    enableListeners(layout) {
        document.getElementById("table").addEventListener('contextmenu', this.listeners.context)
        layout.on("mouse:move", this.listeners.mouseMove);
        layout.on("mouse:down", this.listeners.mouseDown);
        //layout.oncontextmenu = () => { console.debug('context pressed'); };
        fabric.util.addListener(document.body, "keydown", this.listeners.keyDown);
        fabric.util.addListener(document.body, "keyup", this.listeners.keyUp);
        console.debug("drawing listeners enabled");
    }
    disableListeners(layout) {
        document.getElementById("table").removeEventListener('contextmenu', this.listeners.context)
        layout.off("mouse:move", this.listeners.mouseMove);
        layout.off("mouse:down", this.listeners.mouseDown);
        fabric.util.removeListener(document.body, "keydown", this.listeners.keyDown);
        fabric.util.removeListener(document.body, "keyup", this.listeners.keyUp);
        console.debug("drawing listeners disabled");
    }
    mouseMove(options, layout) {
        var pointer;

        if (!this.isNotFirstTuch) { return; }

        pointer = layout.getPointer(options.e);
        var x2 = pointer.x;
        var y2 = pointer.y;

        if (this.layoutSettings.keysPressed.shift) {
            if (Math.abs(this.line.x1 - x2) < Math.abs(this.line.y1 - y2)) {
                x2 = this.line.x1;
            } else {
                y2 = this.line.y1;
            }
        }

        this.line
            .set({
                x2: x2,
                y2: y2,
            })
            .setCoords();

        layout.requestRenderAll();
    }
    setDrawingColor(color){this.drawingColor = color}
    drawLine(options, layout) {
        var pointer = layout.getPointer(options.e);
        if (this.isNotFirstTuch == true) {
            pointer = { x: this.line.x2, y: this.line.y2 };
        }

        var points = [pointer.x, pointer.y, pointer.x, pointer.y];
        this.line = new fabric.Line(points, {
            hasControls: false,
            name: "line",
            strokeWidth: 6,
            fill: this.drawingColor,
            stroke: this.drawingColor,
            originX: "center",
            originY: "top",
            selectable: false,
            targetFindTolerance: true,
        });
        this.isNotFirstTuch = true;
        this.layout.add(this.line);
        console.debug("point added:", points);
    }
    terminate(layout, layoutSettings) {
        this.disableListeners(layout);
        this.isNotFirstTuch = false;
        layout.remove(this.line);
        // layout.forEachObject(function (obj) {
        //     if (Object.prototype.hasOwnProperty.call(obj, "name")) {
        //         obj.set({ selectable: true, hasControls: true }).setCoords();
        //     }
        // });
        layoutSettings.mode = "none";
        layout.requestRenderAll();
        console.debug("line finished");
    }
}
