import { fabric } from "fabric";
import { propertiesExtender } from '@/components/helpers/Helpers'
//Singletone
export class ProductServices {
    static instance
    constructor(layoutSettings, terminalServices) {
        if (ProductServices.instance) { return ProductServices.instance }
        if (!layoutSettings) console.warn('layoutSettings is empty: ', ...layoutSettings)
        this.layoutSettings = layoutSettings;
        this.terminalServices = terminalServices
        ProductServices.instance = this
        return ProductServices.instance
    }
    static getInstance(layoutSettings, terminalServices) {
        if (this.instance) {
            if (layoutSettings || terminalServices) {
                console.warn('Instance already exists. Provided parameters will be ignored.');
            }
            return this.instance;
        }

        if (!layoutSettings || !terminalServices) {
            throw new Error('Please provide all necessary parameters to create a Product services instance.');
        }

        this.instance = new ProductServices(layoutSettings, terminalServices);
        return this.instance;
    }
    formatGroup(group, product, deformation) {
        if (Object.prototype.hasOwnProperty.call(product, "scalableX") && !product.scalableX) {
            console.debug('scalable X: ', product.scalableX);
            group.set({ lockScalingX: true });
        }
        if (Object.prototype.hasOwnProperty.call(product, "scalableY") && !product.scalableY) {
            console.debug('scalable Y: ', product.scalableY);
            group.set({ lockScalingY: true });
        }

        if (Object.prototype.hasOwnProperty.call(product, "snapPositionX")) {
            console.debug('snap positionX: ', product.snapPositionX);
            group.set({ snapPositionXInit: product.snapPositionX + deformation?.left });
            group.set({ snapPositionX: product.snapPositionX + deformation?.left });
        } else {
            group.set({ snapPositionXInit: 0 });
            group.set({ snapPositionX: 0 });
        }

        if (Object.prototype.hasOwnProperty.call(product, "snapPositionY")) {
            console.debug('snap positionY: ', product.snapPositionY);
            group.set({ snapPositionYInit: product.snapPositionY + deformation?.top });
            group.set({ snapPositionY: product.snapPositionY + deformation?.top });
        } else {
            group.set({ snapPositionYInit: 0 });
            group.set({ snapPositionY: 0 });
        }

        if (Object.prototype.hasOwnProperty.call(product, "snapX")) {
            console.debug('snapX: ', product.snapX);
            group.set({ snapX: product.snapX });
        } else {
            group.set({ snapX: true }); //all objects are snapped by default
        }

        if (Object.prototype.hasOwnProperty.call(product, "snapY")) {
            console.debug('snapY: ', product.snapY);
            group.set({ snapY: product.snapY });
        } else {
            group.set({ snapY: true }); //all objects are snapped by default
        }

        if (Object.prototype.hasOwnProperty.call(product, "freeRotate")) {
            console.debug('freeRotate: ', product.freeRotate);
            group.set({ freeRotate: product.freeRotate });
        } else {
            group.set({ freeRotate: false }); //all objects are not free-rotating by default
        }
        group.setControlsVisibility({
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false,
        });
        group.bringToFront();
        group.set({
            borderColor: this.layoutSettings.selectionColor,
            hasControls: true,
            cornerColor: '',
            cornerSize: 9,
            transparentCorners: false
        });
        this.align(group);
    }
    align(obj) {
        let normalizedAngle = obj.angle % 360;
        if (normalizedAngle < 0) {
            normalizedAngle += 360;
        }
    
        const isRight = normalizedAngle % 90 === 0;
        const isRotated = isRight && normalizedAngle !== 0 && normalizedAngle !== 180 && normalizedAngle !== 360;
    
        const adjustPosition = (position, snapPosition) =>
            Math.round((position + snapPosition) / this.layoutSettings.gridSize) * this.layoutSettings.gridSize - snapPosition + this.layoutSettings.canvasOffset;
    
        if (typeof obj.set === "function") {
            if (obj.snapX && isRight) {
                const snapX = isRotated ? obj.snapPositionX : obj.snapPositionY || 0;
                obj.set({ left: adjustPosition(obj.left, snapX) });
            }
            if (obj.snapY && isRight) {
                const snapY = isRotated ? obj.snapPositionY : obj.snapPositionX || 0;
                obj.set({ top: adjustPosition(obj.top, snapY) });
            }
        } else {
            if (obj.snapX) {
                const snapX = isRotated ? obj.snapPositionX : obj.snapPositionY || 0;
                obj.left = adjustPosition(obj.left, snapX);
            }
            if (obj.snapY) {
                const snapY = isRotated ? obj.snapPositionY : obj.snapPositionX || 0;
                obj.top = adjustPosition(obj.top, snapY);
            }
        }
    }
    
    insertProductToCanvas(canvas, objects, product, url) {
        let group;
        /// we need group anyway
        if (objects.objects.length === 1 && objects.objects[0] instanceof fabric.Image) {
            objects.objects[0].set({ name: "core" })
            group = new fabric.Group([objects.objects[0]], objects.options);
        } else {
            group = fabric.util.groupSVGElements(objects.objects, objects.options);
        }

        const desiredWidth = product.width
        const desiredHeight = product.height

        const scaleX = desiredWidth / group.width
        const scaleY = desiredHeight / group.height

        group.set({
            scaleX: scaleX,
            scaleY: scaleY
        })

        console.debug('group width: ', group.width, ' height: ', group.height);
        console.debug('group scaleX: ', scaleX, ' scaleY: ', scaleY);
        console.debug('desiredWidth: ', product.width, ' desiredHeight: ', product.height);

        propertiesExtender([group])

        group.set('id', product.id)
        group.set('name', product.name);
        group.set('boxSize', product.boxWidth + "mm x " + product.boxHeight + "mm",);
        group.set('size', product.width + "mm x " + product.height + "mm",);
        group.set('longName', product.longName);
        group.set('id', product.id);
        group.set('navCode', product.navCode);
        group.set('isProduct', true);
        group.set('descriptionImage', product.descriptionImage);

        group.set('sourcePath', url);

        group.set('lockRotation', !product.freeRotate)

        if (product.terminals) {
            group.set('terminals', product.terminals)
            var terminals = this.terminalServices.createTerminals(product.terminals, group);
            terminals.forEach((terminal) => {
                group.addWithUpdate(terminal);
            })

            var deformation = this.terminalServices.countDeformations(product.terminals, this.terminalServices.terminalSize, product.width, product.height)
            console.debug('deformation of,', product.name, ': ', deformation);
        }
        this.formatGroup(group, product, deformation);

        group.set('top', 0)
        group.set('left', 0)
        canvas.add(group);

        console.info('%cproduct added ' + product.name, 'color: darkseagreen;', group)
    }
    addBlackBox(product, layout) {
        var shape = new fabric.Rect({
            id: product.id,
            fill: "#000000",
            stroke: "#7d7d81",
            originX: "left",
            originY: "top",
            angle: 0,
            visible: true,
            strokeWidth: 0,
            hasBorders: false,
            width: product.width,
            height: product.height
        });

        shape = new fabric.Group([shape], {
            name: "BLACK-BOX",
            id: product.id,
            width: product.width,
            height: product.height,
            isProduct: true
        });
        this.formatGroup(shape, product)

        layout.add(shape);
        propertiesExtender([shape])
    }
    async addProduct(product, canvas) {

        if (!product) return

        if (product?.name === 'BLACK-BOX') {
            this.addBlackBox(product, canvas)
            return
        }
        var imgUrlBase = process.env.VUE_APP_PRODUCT_PATH;
        var url = (imgUrlBase + product.svgImage + "?" + Date.now());
        try {
            const objects = await new Promise((resolve, reject) => {
                fabric.loadSVGFromURL(url, (objects, options) => {
                    if (objects && options) {
                        resolve({ objects, options });
                    } else {
                        reject("Failed to load SVG");
                    }
                });
            });

            this.insertProductToCanvas(canvas, objects, product, url)
        } catch (error) {
            console.error("Oops:", error);
        }
    }
    getProductList(canvas) {
        var products = canvas.getObjects().filter((obj) => obj.isProduct)
        var output = []
        products.forEach((element, index) => {
            output.push({
                id: index,
                name: element.longName || '-',
                code: element.name || '-',
                size: '' + element.width + 'x' + element.height || '-',
                boxSize: element.boxSize || '-',
                navCode: element.navCode || '-'

            })
        });
        return output
    }
}
