<template>
  <div class="btn-group me-2" role="group" aria-label="ProductSelector">
    <button
      class="btn btn-outline-dark  d-flex flex-column align-items-center"
      type="button"
      title="Show/hide item terminals"
      @click="add(selectedProduct?.id)"
    >
      <i class="bi bi-plus"></i>
      <span>add</span>
    </button>
    <!-- <div class="btn-group" role="group"> -->
      <div class="dropdown">
        <button
          class="btn btn-outline-dark dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ref="dropdownButton"
          title="Select product to add"
        >
          {{ selectedProduct?.name || "select product" }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <input
            class="form-control"
            type="text"
            ref="dropdownInput"
            v-model="inputVal"
            placeholder="type..."
          />
          <li v-for="(product, key) in productList" :key="key">
            <h6 class="dropdown-header">{{ product.category }}</h6>
            <a
              class="dropdown-item"
              v-for="product in product.list"
              :key="product.id"
              @click="$store.dispatch('updateSelectedProduct', product)"
              href="#"
              >{{ product.longName }}</a
            >
            <hr class="hr" />
          </li>
        </ul>
      </div>
      <div id="objViewer">
        <OBJViewer ref="objViewer" />
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
import { ProductServices } from "@/components/Planner/Services/ProductServices";
import { TerminalServices } from "@/components/Planner/Services/TerminalServices";
import LayoutServices from "@/components/Planner/Services/LayoutServices.js";
import OBJViewer from "../ObjViewer/ObjViewerView.vue";
//import { fabric } from "fabric";
export default {
  name: "ProductSelector",
  components: {
    OBJViewer,
  },
  props: {
    layout: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      productList: [],
      services: {},
      inputVal: "",
    };
  },
  computed: {
    layoutSettings() {
      return this.$store.state.layoutSettings;
    },
    selectedProduct: {
      get() {
        return this.$store.getters.selectedProduct;
      },
      set(value) {
        this.$store.dispatch("updateSelectedProduct", value);
      },
    },
    productsCategorized() {
      return this.$store.getters.categorizedProducts;
    },
  },
  methods: {
    filterProducts(inputval) {
      this.productList = this.productsCategorized
        .map((group) => ({
          ...group,
          list: group.list.filter(
            (product) =>
              product?.name.toLowerCase().includes(inputval.toLowerCase()) ||
              product?.longName.toLowerCase().includes(inputval.toLowerCase())
          ),
        }))
        .filter((group) => group.list.length > 0);
    },
    async add(inp) {
      if (!inp) {
        this.animateNegative(document.getElementById("dropdownMenuButton1"));
      }
      try {
        var product = await this.$store.dispatch("product", inp);
        this.services.productServices.addProduct(product, this.layout);
        this.animatePositive(document.getElementById("dropdownMenuButton1"));
      } catch (error) {
        console.error("Failed when adding product to layout: ", error);
        this.animateNegative(document.getElementById("dropdownMenuButton1"));
      }
    },
    clearInput() {
      this.inputVal = "";
    },
    focusOnInput() {
      this.$refs.dropdownInput.focus();
    },
    animatePositive(element) {
      element.style.animation = "none";

      setTimeout(() => {
        element.style.animation = "positiveAnimation 2s";
      }, 50);
    },
    animateNegative(element) {
      element.style.animation = "none";

      setTimeout(() => {
        element.style.animation = "negativeAnimation 2s";
      }, 50);
    },
  },
  watch: {
    inputVal(newVal) {
      this.filterProducts(newVal);
    },
  },
  mounted() {
    this.services.layoutServices = new LayoutServices();
    this.services.terminalServices = new TerminalServices(this.layout);
    this.services.productServices = new ProductServices(
      this.layoutSettings,
      this.services.terminalServices
    );
    this.$refs.dropdownButton.addEventListener(
      "shown.bs.dropdown",
      this.focusOnInput
    );
    this.$refs.dropdownButton.addEventListener(
      "hidden.bs.dropdown",
      this.clearInput
    );
    this.productList = this.$store.getters.categorizedProducts;
  },
  beforeUnmount() {
    this.$refs.dropdownButton.removeEventListener(
      "hidden.bs.dropdown",
      this.clearInput
    );
    this.$refs.dropdownButton.removeEventListener(
      "shown.bs.dropdown",
      this.focusOnInput
    );
  },
  beforeMount() {},
};
</script>

<style>
#dropdownMenuButton1 {
  height: 63px;
  min-width: 300px;
  color: black;
}
.dropdown-menu {
  max-height: 85vh;
  overflow: auto;
}
</style>