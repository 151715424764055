<template>
  <div class="container my-3">
  <h5>Line Settings</h5>
  <hr/>
  <div class="row align-items-start">
    <div class="col-auto">
      <label for="lineStroke" class="col-form-label">Stroke:</label>
    </div>
    <div class="col-3">
      <input type="number" id="lineStroke" class="form-control" v-model="this.strokeWidth" placeholder="type line stroke" @input="updateLineStrokeWidth(false)">
    </div>
    <div class="col-auto">
      <button class="btn btn-outline-dark" title="Apply to all" @click="updateLineStrokeWidth(true)">
        <i class="bi bi-check2-all"></i>
      </button>
    </div>
  </div>
  
  <div class="row mt-3 align-items-start">
    <div class="col-auto">
      <label for="exampleColorInput" class="col-form-label">Color:</label>
    </div>
    <div class="col-auto">
      <input type="color" class="form-control form-control-color" id="exampleColorInput" v-model="activeColor" title="Choose your color" @input="updateLineColor($event.target.value, false)">
    </div>
    <div class="col-auto">
      <ColorSelector :key="selected" :activeColor="activeColor" ref="blackBoxColorSelector" @update:selectedColor="updateLineColor" />
    </div>
    
  </div>
</div>
<hr/>
</template>

<script>
import ColorSelector from "@/components/helpers/ColorSelector.vue";
export default {
  components: {
    ColorSelector,
  },
  props: {
    layout: Object
  },
  data() {
    return {
      strokeWidth: 4,
      activeColor: "",
    };
  },
  computed: {
    selected() {
      return this.$store.getters.selection;
    },
  },
  methods: {
    updateLineColor(color, applyToAll) {
      console.debug("updating line color");
      if (applyToAll) {
        this.layout.forEachObject((obj) => {
          if (obj?.type === "line") {
            obj.set("stroke", color);
            obj.dirty = true;
          }
        });
        this.layout.requestRenderAll();
        return;
      }

      var activeObject = this.layout.getActiveObject();
      if (activeObject) {
        if (activeObject.type === "activeSelection") {
          activeObject.forEachObject(function (obj) {
            if (obj?.type === "line") {
              obj.set("stroke", color);
              obj.dirty = true;
            }
          });
        } else {
          if (activeObject?.type === "line") {
            activeObject.set("stroke", color);
            activeObject.dirty = true;
          }
        }
      }
      this.layout.requestRenderAll();
    },
    updateLineStrokeWidth(applyToAll) {
      console.debug("updating line width");
      var val = this.strokeWidth;
      if (applyToAll) {
        this.layout.forEachObject((obj) => {
          if (obj?.type === "line") {
            obj.set("strokeWidth", val);
            obj.dirty = true;
          }
        });
        this.layout.requestRenderAll();
        return;
      }

      var activeObject = this.layout.getActiveObject();
      if (activeObject) {
        if (activeObject.type === "activeSelection") {
          activeObject.forEachObject(function (obj) {
            if (obj?.type === "line") {
              obj.set("strokeWidth", val);
              obj.dirty = true;
            }
          });
        } else {
          if (activeObject?.type === "line") {
            activeObject.set("strokeWidth", val);
            activeObject.dirty = true;
          }
        }
      }
      this.layout.requestRenderAll();
    },
  },
  watch: {
    selected(newVal) {
      if (newVal?.type == "line") {
        this.strokeWidth = newVal.strokeWidth;
        this.activeColor = newVal.stroke;
      }
    },
  },
};
</script>

<style>
</style>