<template>
<h5>Rotation</h5>
<hr/>
  <div class="row align-items-start">
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        title="Rotate object clockwise"
        @click="services.layoutServices.rotate(angle, layout)"
      >
        <i class="bi bi-arrow-clockwise"></i>
        <span>clockwise</span>
      </button>
    </div>
    <div class="col-3">
      <input
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="form-control border-dark"
        type="number"
        v-model="this.angle"
        placeholder="rotation angle"
        step="45"
        @input="validateIntegerInput"
      />
    </div>
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        title="Rotate object counter-clockwise"
        @click="services.layoutServices.rotate(-angle, layout)"
      >
        <i class="bi bi-arrow-counterclockwise"></i>
        <span>counter</span>
      </button>
    </div>
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        title="Rotate object counter-clockwise"
        @click="services.layoutServices.setRotationZero(layout)"
      >
        <i class="bi bi-align-top"></i>
        <span>rotate to 0</span>
      </button>
    </div>
  </div>
  <hr/>
  <h5>Other</h5>
<hr/>
  <div class="row align-items-start">
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        @click="services.layoutServices.sendToFront(layout)"
        title="Move object to front [PageUp]"
      >
        <i class="bi bi-layer-forward"></i>
        <span>to front</span>
      </button>
    </div>
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        @click="services.layoutServices.sendToBack(layout)"
        title="Move object to back [PageDown]"
      >
        <i class="bi bi-layer-backward"></i>
        <span>to back</span>
      </button>
    </div>
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        @click="services.layoutServices.deleteActiveItem(layout)"
        title="Delete object [Del]"
      >
        <i class="bi bi-eraser-fill"></i>
        <span>remove</span>
      </button>
    </div>
    <div class="col-auto">
      <button
        :disabled="!canvasElementSelected"
        :class="{ 'disabled-button': !canvasElementSelected }"
        class="btn btn-outline-dark d-flex flex-column align-items-center"
        title="Disable active objects selection"
        @click="disableSelected()"
      >
        <i class="bi bi-snow3"></i>
        <span>freeze</span>
      </button>
    </div>
  </div>
  <hr/>
</template>

<script>
import { fabric } from "fabric";
import { TerminalServices } from "@/components/Planner/Services/TerminalServices";
import LayoutServices from "@/components/Planner/Services/LayoutServices.js";
import { EventBus } from "@/eventBus";

export default {
  name: "ProductManipulator",
  props: {
    layout: Object,
  },
  data() {
    return {
      services: {},
      angle: 90,
    };
  },
  computed: {
    layoutSettings() {
      return this.$store.state.layoutSettings;
    },
    selected() {
      return this.$store.getters.selection;
    },
    canvasElementSelected() {
      return this.selected instanceof fabric.Object;
    },
  },
  mounted() {
    this.services.layoutServices = new LayoutServices();
    this.services.terminalServices = new TerminalServices(this.layout);
    EventBus.on("clockwise90", () => {
      this.services.layoutServices.rotate(90, this.layout);
    });
    EventBus.on("clockwise", () => {
      this.services.layoutServices.rotate(this.angle, this.layout);
    });
    EventBus.on("counter-clockwise90", () => {
      this.services.layoutServices.rotate(-90, this.layout);
    });
    EventBus.on("restoreAngle", () => {
      this.services.layoutServices.setRotationZero(this.layout);
    });
    EventBus.on("toFront", () => {
      this.services.layoutServices.sendToFront(this.layout);
    });
    EventBus.on("toBack", () => {
      this.services.layoutServices.sendToBack(this.layout);
    });
    EventBus.on("deleteActive", () => {
      this.services.layoutServices.deleteActiveItem(this.layout);
    });
    EventBus.on("freezeOne", () => {
      this.disableSelected();
    });
  },
  methods: {
    validateIntegerInput() {
      this.angle = Math.round(this.angle);
    },
    disableSelected() {
      this.$store.commit("SET_FREEZED", true);
      this.services.layoutServices.disableSelectionSelected(this.layout);
    },
  },
};
</script>

<style>
</style>