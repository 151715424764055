export class Scheme {
    constructor(tableParameters, layoutSettings, grid, gridSpacing, canvas, name, products = [], canvasOffset){
        this.tableParameters = tableParameters
        this.layoutSettings = layoutSettings
        this.grid = grid
        this.gridSpacing = gridSpacing
        this.canvas = canvas
        this.name =  name
        this.products = products
        this.canvasOffset = canvasOffset
    }
}
export default Scheme