<template>
  <div class="fullscreen-overlay">
    <!--There's the container that centers it-->
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden"></span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  backdrop-filter: blur(6px);
  z-index: 2;
  display: flex;
  justify-content: center; /* Centruoja spinnerį horizontaliai */
  align-items: center;
  
}
.spinner-border {
  width: 13rem;
  height: 13rem;  
}

</style>