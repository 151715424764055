import store from '@/store/index'

class LayoutHistory {
    static instance;
    constructor(canvas, canvasServices, settings) {
        if (LayoutHistory.instance) {
            return LayoutHistory.instance
        }
        this.canvas = canvas;
        this.canvasServices = canvasServices;
        this.settings = settings;
        this.undoStack = [];
        this.redoStack = [];
        this.saveState();
        this.listeners = {
            modify: this.modifyListener.bind(this),
            add: this.addListener.bind(this)
        }
        this.isCrashed = false
        LayoutHistory.instance = this
        return LayoutHistory.instance
    }
    static getInstance(canvas, canvasServices, settings) {
        if (this.instance) {
            if (canvas || canvasServices || settings) {
                console.warn('Instance already exists. Provided parameters will be ignored.');
            }
            return this.instance;
        }

        if (!canvas || !canvasServices || !settings) {
            throw new Error('Please provide all necessary parameters to create a LayoutHistory instance.');
        }

        this.instance = new LayoutHistory(canvas, canvasServices, settings);
        return this.instance;
    }
    addListener() {
        this.saveState();
    }
    modifyListener() {
        this.saveState();
    }
    enableListeners() {
        this.canvas.on("object:modified", this.listeners.modify)
        this.canvas.on("object:added", this.listeners.add)
    }
    disaableListeners(layout) {
        layout.off("object:modified", this.listeners.modify)
        layout.off("object:added", this.listeners.add)
    }
    saveState() {
        try {
            if (store.state.history.isTrackingPaused) {
                console.debug('history processing');
                return
            }
            console.debug('saving state');
            this.redoStack = [];
            this.undoStack.push(this.canvas.toJSON());
        } catch (error) {
            console.error('cannot save it')
            this.crashed = true
        }
    }

    async undo() {
        console.debug('undo');
        store.commit('PAUSE_TRACKING')
        if (this.undoStack.length > 1) {
            this.redoStack.push(this.undoStack.pop());
            let prevState = this.undoStack[this.undoStack.length - 1];
            await this.canvasServices.loadFromJson(prevState, this.canvas, this.settings)
            this.canvasServices.addGrid(this.canvas, this.settings,store.getters.widthLabel,store.getters.heightLabel);
        }
        store.commit('RESUME_TRACKING')
    }

    async redo() {
        console.debug('redo');
        store.commit('PAUSE_TRACKING')
        if (this.redoStack.length > 0) {
            let nextState = this.redoStack.pop();
            this.undoStack.push(nextState);
            await this.canvasServices.loadFromJson(nextState, this.canvas, this.settings)
            this.canvasServices.addGrid(this.canvas, this.settings,store.getters.widthLabel,store.getters.heightLabel);
        }
        store.commit('RESUME_TRACKING')
    }
}
export default LayoutHistory
