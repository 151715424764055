<template>
  <button
    class="btn btn-outline-dark d-flex flex-column align-items-center"
    title="Print/export to PDF"
    data-bs-toggle="modal"
    data-bs-target="#PrintModal"
    @click="show()"
  >
    <i class="bi bi-printer"></i>
    <span>print</span>
  </button>

  <div
    class="modal fade"
    id="PrintModal"
    tabindex="-1"
    aria-labelledby="Modal3DLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content" id="pf">
        <PdfContent
          v-if="showMe"
          :id="metadata.name"
          :metadata="metadata"
          :layout="layout"
        />
      </div>
    </div>
  </div>
</template>
<script>
import bootstrapServices from '@/components/Planner/Services/botstrapServices';
import PdfContent from "./PdfContent.vue";
import { EventBus } from '@/eventBus';

export default {
  emits: ["save:layout"],
  props: {
    layout: Object,
  },
  components: {
    PdfContent,
  },
  data() {
    return {
      showMe: false,
    };
  },
  computed: {
    metadata() {
      return this.$store.state.layoutSettings;
    },
  },
  methods: {
    show() {
      if (this.metadata.name) {
        this.showMe = true;
      } else {
        var self = this;
        this.$emit("save:layout", () => {
          self.showMe = true;
        });
      }
    }, 
    showModal(){
      this.show();
      bootstrapServices.methods.openModal("PrintModal")}
  },
  mounted(){
    EventBus.on('print', this.showModal);
  },
  beforeMount(){
    EventBus.off('print', this.showModal);
  }
};
</script>
<style lang="css" scoped>
h1,
h2 {
  color: black;
}
#PrintModal {
  backface-visibility: visible;
  background-color: white;
}
</style>
