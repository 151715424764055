import { fabric } from "fabric";

export class TerminalServices {
    constructor() {
        this.terminalSize = 7;
        this.isTerminalsVisible = true;
    }
    resizeTerminals(value, layout) {
        this.terminalSize = this.terminalSize + value;

        var size = this.terminalSize;
        console.debug("resizing terminal to: ", size);
        var groups = layout.getObjects();
        groups.forEach((group) => {
            if (group instanceof fabric.Group) {
                group.getObjects().forEach((terminal) => {
                    if (terminal?.name === 'terminal') {
                        terminal.set({
                            width: size,
                            height: size
                        });
                        terminal.set({
                            top: terminal.top - (value / 2),
                            left: terminal.left - (value / 2)
                        });
                        layout.requestRenderAll();
                    }
                });
            }
        });
    }
    countDeformations(coordinatesOfAllTerminals, terminalSize=0, width, height, productCoordinates={left:0,top:0}) {
        const deformation = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
        coordinatesOfAllTerminals.forEach((terminal) => {
            var isTerminalOutOfRange =
            terminal.top < 0
                || terminal.left < 0
                || terminal.left > width+productCoordinates.left
                || terminal.bottom > height+productCoordinates.top

            if (isTerminalOutOfRange) console.error("Some terminals placed out of product range, please fix configs in admin mode: ", terminal.top < 0, terminal.left < 0, terminal.left > width, terminal.bottom > height)
            
            var leffDeformation = Math.min(terminal.left - productCoordinates.left - terminalSize / 2, 0);
            var topDeformation = Math.min(terminal.top - productCoordinates.top - terminalSize / 2, 0);
            
            var rightDeformation = Math.max(terminal.left - (productCoordinates.left + width) + terminalSize / 2, 0);
            var bottomDeformation = Math.max(terminal.top - (productCoordinates.top + height) + terminalSize / 2, 0);
            
            deformation.left = Math.min(leffDeformation, deformation.left);
            deformation.top = Math.min(topDeformation, deformation.top);
            deformation.right = Math.max(rightDeformation, deformation.right);
            deformation.bottom = Math.max(bottomDeformation, deformation.bottom);
        })
        console.debug('deformation: ', deformation);
        return deformation

    }
    createTerminals(coordinates) {
        const topCorrection = 0 //group.top
        const leftCorrection = 0//group.left
        var terminals = [];
        var size = this.terminalSize;
        var self = this


        console.debug('terminalSize: ', size);
        coordinates.forEach(function (coordinate) {

            if (Object.prototype.hasOwnProperty.call(coordinate, 'top') && Object.prototype.hasOwnProperty.call(coordinate, 'left')) {
                const terminalTop = topCorrection + coordinate.top - size / 2
                const terminalTLeft = leftCorrection + coordinate.left - size / 2
                console.debug('terminal top: ', terminalTop, ' terminalTLeft: ', terminalTLeft);
                var terminal = new fabric.Rect({
                    name: "terminal",
                    top: terminalTop,
                    left: terminalTLeft,
                    width: size,
                    height: size,
                    fill: 'red',
                    visible: self.isTerminalsVisible
                });

                terminal.toObject = (function (toObject) {
                    return function () {
                        return fabric.util.object.extend(toObject.call(this), {
                            name: "terminal",
                        });
                    };
                })(terminal.toObject);


                terminals.push(terminal);
            }
        });
        console.debug(terminals.length, " terminals found");
        return terminals;
    }
    getTerminals(layout) {
        var terminals = [];
        var groups = layout.getObjects();
        groups.forEach((group) => {
            if (group instanceof fabric.Group) {
                group.getObjects().forEach((terminal) => {
                    if (terminal?.name === 'terminal') {
                        terminals.push(terminal);
                    }
                });
            }
        });
        console.debug(terminals.length, " terminals found");
        return terminals;
    }
    hideShowTerminals(layout) {
        this.isTerminalsVisible = !this.isTerminalsVisible

        var terminals = this.getTerminals(layout);
        terminals.forEach((terminal) => {
            terminal.set({ visible: this.isTerminalsVisible });
        });

        layout.requestRenderAll();
        if (this.isTerminalsVisible)
            console.info('%cdisplay ' + terminals.length, 'color: darkseagreen;')
        else
            console.info('%chide ' + terminals.length, 'color: darkseagreen;')

    }
}
