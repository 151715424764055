
import { fabric } from "fabric";

function productExtender(obj) {
  
  obj.toObject = (function (toObject) {
    return function () {
      return fabric.util.object.extend(toObject.call(this), {
        id: this.id,
        name: this.name,
        strokeWidth: this.strokeWidth,
        lockRotation: this.lockRotation,
        styles: this.styles,
        snapX: this.snapX,
        snapY: this.snapY,
        snapPositionY: this.snapPositionY,
        snapPositionX: this.snapPositionX,
        freeRotate: this.freeRotate,
        lockScalingX: this.lockScalingX,
        lockScalingY: this.lockScalingY,
        terminals: this.terminals,
        descriptionImage: this.descriptionImage,
        isProduct: this.isProduct
      });
    };
  })(obj.toObject);
}
function blackBoxExtender(shape){
  
  shape.toObject = (function (toObject) {
    return function () {
        return fabric.util.object.extend(toObject.call(this), {
            name: "BLACK-BOX",
            strokeWidth: this.strokeWidth,
            lockRotation: this.lockRotation,
            id: this.id,
            styles: this.styles,
            snapX: this.snapX,
            snapY: this.snapY,
            snapPositionY: this.snapPositionY,
            snapPositionX: this.snapPositionX,
            freeRotate: this.freeRotate,
            lockScalingX: this.lockScalingX,
            lockScalingY: this.lockScalingY,
            terminals: this.terminals,
            isProduct:true
        });
    };
})(shape.toObject);
}
export function propertiesExtender(objs) {
  //iterating array
  objs.forEach((obj) => {

    setControlsVisibility(obj);
    
    console.debug('extending properties: ', obj.name || obj.type);
    //checking if blackbox
    if(obj?.name==="BLACK-BOX"){
      blackBoxExtender(obj);
      return;
    }
    //extending terminals inside
    if (typeof obj.getObjects === 'function')
    obj.getObjects()?.forEach((el) => {
      if (el?.name === 'terminal')
        terminalExtender([el]);
    })
    //finnaly extending product
    productExtender(obj);
    if (obj?.type === 'line' || obj?.name === 'ruler')
    {
      obj.hasControls=false;
    }
  })
  return objs;
}

export function terminalExtender(terminals) {
  console.debug('extending terminals: ');
  terminals.forEach((terminal) => {
    terminal.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          name: "terminal",
        });
      };
    })(terminal.toObject);
  });
}
function setControlsVisibility(obj){
  if(!obj?.name && obj?.type != "i-text")//group
  {
    obj.set({ hasControls: false })
    return
  }
  if(obj?.name==="BLACK-BOX")
  {
    obj.setControlsVisibility({
      bl: true,
      mb: true,
      br: true,
      ml: true,
      mr: true,
      mt: true,
      tl: true,
      tr: true,
      mtr: false,
    })
    .setCoords();
    return
  }
  if(obj?.type === "i-text")
  {
      obj.setControlsVisibility({
        bl: true,
        mb: false,
        br: true,
        ml: false,
        mr: false,
        mt: false,
        tl: true,
        tr: true,
        mtr: false,
      })
      .setCoords();
      return
  }
  if(/^(tube)/i.test(obj.name))
  {
      obj.setControlsVisibility({
        bl: false,
        mb: true,
        br: false,
        ml: false,
        mr: false,
        mt: true,
        tl: false,
        tr: false,
        mtr: false,
      })
      .setCoords();
      return
    }
}
