<template>
  <div class="container my-3">
    <h5>Black box settings</h5>
    <div class="row">
      <label class="col col-form-label">width: </label>

      <input
        id="bbWidth"
        class="form-control border-dark custom-max-width"
        type="number"
        v-model="this.blackBox.width"
        placeholder="type black box width"
        @input="$emit('update:blackBox', this.blackBox)"
      />

      <label class="col col-form-label">height: </label>

      <input
        id="bbHeight"
        class="form-control border-dark custom-max-width"
        type="number"
        v-model="this.blackBox.height"
        placeholder="type black box height"
        @input="$emit('update:blackBox', this.blackBox)"
      />
      <label class="col col-form-label">color: </label>
      <input
        type="color"
        class="form-control form-control-color"
        id="exampleColorInput"
        v-model="activeColor"
        title="Choose your color"
        @input="updateBlackBoxColor($event.target.value, opacity, false)"
      />
      <label class="col col-form-label">opacity: </label>
      <input
        id="bbOpacity"
        class="form-control border-dark custom-max-width"
        type="number"
        step="0.1"
        v-model="this.opacity"
        placeholder="type black box opacity"
        @input="updateBlackBoxColor($event.target.value, opacity, false)"
      />

      <ColorSelector
        :key="selected"
        :activeColor="activeColor"
        ref="blackBoxColorSelector"
        @update:selectedColor="updateBlackBoxColor"
        class="col"
      />
    </div>
  </div>
</template>
<script>
import { fabric } from "fabric";
import ColorSelector from "@/components/helpers/ColorSelector.vue";
export default {
  components: {
    ColorSelector,
  },
  props: {
    layout: Object,
    services: Object,
  },
  data() {
    return {
      blackBox: { width: 0, height: 0 },
      activeColor: "",
      opacity: 1,
    };
  },
  computed: {
    selected() {
      return this.$store.getters.selection;
    },
    selectedColor() {
      console.debug(
        "selectedColor: ",
        this.layout.getActiveObject()?.getObjects()[0]?.fill || ""
      );
      return this.layout.getActiveObject()?.getObjects()[0]?.fill || "";
    },
    canvasElementSelected() {
      //checking proxy is empty ...
      return this.selected instanceof fabric.Object;
    },
  },
  methods: {
    updateBlackBoxColor(color, applyToAll) {
      this.services.layoutServices.updateBlackBoxColor(
        color,
        this.opacity,
        applyToAll,
        this.layout
      );
    },
    updateSizes(scaling) {
      if (this.selected?.name !== "BLACK-BOX") {
        return;
      }
      console.debug(
        "this.selected.width * this.selected.scaleX",
        this.selected,
        scaling
      );
      this.blackBox.width = parseInt(
        this.selected.width * (scaling?.scaleX || 1)
      );
      this.blackBox.height = parseInt(
        this.selected.height * (scaling?.scaleY || 1)
      );
      console.debug("blackBox: ", this.blackBox);
    },
  },
  watch: {
    selected(newVal) {
      if (newVal?.name === "BLACK-BOX") {
        if (this.canvasElementSelected)
          if (newVal && typeof newVal.getObjects === "function") {
            this.activeColor = newVal.getObjects()[0]?.fill;
            this.opacity = newVal.getObjects()[0]?.opacity || 1;
          } else {
            console.error("newVal does not have getObjects method");
            this.activeColor = null;
            this.opacity = 1;
          }
        this.blackBox.width = parseInt(
          this.selected.width * (this.selected?.scaleX || 1)
        );
        this.blackBox.height = parseInt(
          this.selected.height * (this.selected?.scaleY || 1)
        );
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.row > * {
  background: rgba(255, 255, 255, 0.5);
}
.custom-max-width {
  max-width: 100px;
}
</style>
