export default{
    namespaced:false,
    state(){
        return{
            isTrackingPaused:false
        }
    },
    mutations:{
        PAUSE_TRACKING(state){
            state.isTrackingPaused = true
        },
        RESUME_TRACKING(state){
            state.isTrackingPaused = false
        }
    }
}