<template>
  <section>
    <div class="loader">
      <span style="--i: 1"></span>
      <span style="--i: 2"></span>
      <span style="--i: 3"></span>
      <span style="--i: 4"></span>
      <span style="--i: 5"></span>
      <span style="--i: 6"></span>
      <span style="--i: 7"></span>
      <span style="--i: 8"></span>
      <span style="--i: 9"></span>
      <span style="--i: 10"></span>
      <span style="--i: 11"></span>
      <span style="--i: 12"></span>
      <span style="--i: 13"></span>
      <span style="--i: 14"></span>
      <span style="--i: 15"></span>
      <span style="--i: 16"></span>
      <span style="--i: 17"></span>
      <span style="--i: 18"></span>
      <span style="--i: 19"></span>
      <span style="--i: 20"></span>
      <div id="spinner-text">
        <h1 ref="spinnertext">{{ progress }} %</h1>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "spin-ner",
  data() {
    return {
      progress: 0,
    };
  },
  methods: {
    setProgress(progress) {
      this.progress = progress;
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#spinner-text {
  animation: animateTx 5s infinite;
}
section {
    border: 2px solid red;
  position: fixed;
  top: 0;
  left: 0%;
  right: 0;
  bottom: 0;
  text-align: center;

  animation: animateBg 10s linear;
}
section:before {
  content: "";
  height: 50%;
  display: inline-block;
  vertical-align: middle;
}
@keyframes animateTx {
  0% {
    color: rgb(0, 0, 0);
  }
  100% {
    color: rgb(0, 0, 0);
  }
}
@keyframes animateBg {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
section .loader {
  position: relative;
  left: 50%;
  width: 120px;
  height: 120px;
}
section .loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
  z-index: 2;
}
section .loader span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 0 10px #565656, 0 0 50px #414141, 0 0 120px #7b7b7b;
  animation: animate 1.8s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}
#spinner-text {
  padding-top: 35px;
  z-index: 15;
}
@keyframes animate {
  0% {
    transform: scale(1);
  }
  80%,
  100% {
    transform: scale(0);
  }
}
</style>