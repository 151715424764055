<template>
  <div class="container">
    <div class="d-flex justify-content-start">
      <div class="btn-group">
        <div v-for="menu in dropdownMenus" :key="menu.name" class="dropdown">
          <a
            class="btn btn-outline dropdown-toggle"
            href="#"
            role="button"
            :id="`dropdownMenu${menu.name}`"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ menu.name }}
          </a>
          <ul
            class="dropdown-menu"
            :aria-labelledby="`dropdownMenu${menu.name}`"
          >
            <li v-for="item in menu.items" :key="item.name">
              <a
                :class="['dropdown-item', { disabled: item.disabled }]"
                href="#"
                @click="handleClick(item.emitEvent)"
                :title="item.description"
                :id="item?.id"
                :data-toggle="item?.isModal"
              >
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/eventBus";
import Swal from "sweetalert2/";
import {version} from "@/../package";
import $ from "jquery";

export default {
  data() {
    return {
      dropdownMenus: [
        {
          name: "Layout",
          items: [
            {
              name: "New",
              description: "Create a new layout",
              emitEvent: "newLayout",
              disabled: false,
            },
            {
              name: "Save",
              description: "Save the current layout",
              emitEvent: "save",
              disabled: false,
            },
            {
              name: "Save copy",
              description: "Save a copy of the current layout",
              emitEvent: "saveAs",
              disabled: false,
            },
            {
              name: "Load",
              description: "Deletes everything, loads another scheme",
              emitEvent: "load",
              disabled: false,
            },
            {
              name: "Import",
              description: "Add scheme to existing layout",
              emitEvent: "import",
              disabled: false,
            },
            {
              name: "Export to PNG",
              description: "Export your layout as a PNG image",
              emitEvent: "export to PNG",
              disabled: false,
            },
            {
              name: "Clear",
              description: "Clear the current layout",
              emitEvent: "newLayout",
              disabled: false,
            },
            {
              name: "Print",
              description: "Print the current layout",
              emitEvent: "print",
              disabled: false,
            },
            {
              name: "Show statistics",
              description: "Count lines by colors",
              emitEvent: "countLines",
              disabled: false,
            },
            {
              name: "Toggle units",
              description: "Change the units of measurement",
              emitEvent: "switchUnits",
              disabled: false,
              separator: true,
            },
            {
              name: "Settings",
              description: "Configure your settings",
              emitEvent: "openSettings",
              disabled: false,
            },
            {
              name: "Hide/Show layout",
              description: "Hide/Show layout",
              emitEvent: "hideShowlayout",
              disabled: false,
            },
          ],
        },
        {
          name: "Edit",
          items: [
            {
              name: "Undo",
              description: "Undo last action",
              emitEvent: "undo",
              disabled: false,
            },
            {
              name: "Redo",
              description: "Redo last action",
              emitEvent: "redo",
              disabled: false,
            },
            {
              name: "Rotate by 90 deg CW",
              description: "Rotate 90 degrees clockwise",
              emitEvent: "clockwise90",
              disabled: false,
              separator: true,
            },
            {
              name: "Rotate by 90 deg CCW",
              description: "Rotate 90 degrees counter-clockwise",
              emitEvent: "counter-clockwise90",
              disabled: false,
            },
            {
              name: "Rotate by angle…",
              description: "Rotate by specific angle",
              emitEvent: "clockwise",
              disabled: false,
            },
            {
              name: "Restore angle",
              description: "Restore original angle",
              emitEvent: "restoreAngle",
              disabled: false,
            },
            {
              name: "Move to front",
              description: "Move object to front",
              emitEvent: "toFront",
              disabled: false,
              separator: true,
            },
            {
              name: "Move to back",
              description: "Move object to back",
              emitEvent: "toBack",
              disabled: false,
            },
            {
              name: "Delete selected object",
              description: "Delete the selected object",
              emitEvent: "deleteActive",
              disabled: false,
              separator: true,
            },
            {
              name: "Freeze selected element",
              description: "Freeze the selected element",
              emitEvent: "freezeOne",
              disabled: false,
            },
          ],
        },
        {
          name: "Draw",
          items: [
            {
              name: "Line",
              description: "Draw a line",
              emitEvent: "draw",
              disabled: false,
            },
            {
              name: "Ruler",
              description: "Use a ruler",
              emitEvent: "ruller",
              disabled: false,
            },
            {
              name: "Textbox",
              description: "Create a text box",
              emitEvent: "text",
              disabled: false,
            },
          ],
        },
        {
          name: "View",
          items: [
            {
              name: "Show/hide terminals",
              description: "Show or hide terminals",
              emitEvent: "toggleTerminals",
              disabled: false,
            },
            {
              name: "Terminal size",
              description: "Adjust terminal size",
              disabled: true,
            },
            {
              name: "Unfreeze all",
              description: "Unfreeze all elements",
              emitEvent: "free",
              disabled: false,
              separator: true,
            },
          ],
        },
        {
          name: "Help",
          items: [
            {
              name: "About",
              description: "About (Api and frontend versions)",
              emitEvent: "about",
              disabled: false,
            },
            {
              name: "Debug",
              description: "Debug to console",
              emitEvent: "debug",
              disabled: false,
            },
            {
              name: "Send feedback",
              description: "Send feedback (JIRA)",
              disabled: false,
              id: "feedback-button",
              emitEvent: "none",
              isModal: "modal",
            },
          ],
        },
      ],
      apiVersionData: "",
    };
  },

  methods: {
    async fetchApiVersion() {
      const url = process.env.VUE_APP_API_BASE_URL + "system/version";
      try {
        const response = await fetch(url);
        const version = await response.text(); 
        this.apiVersionData = version;
      } catch (error) {
        this.apiVersionData = "Unknown";
      }
    },
    handleClick(item) {
      console.debug("event called: ", item);
      if (!item.disabled) {
        EventBus.emit(item, "");
      }
    },

    showAbout() {
      Swal.fire({
        
        showCloseButton: true,
        confirmButtonText: "Close",
        confirmButtonColor: "#686665",
        html: `
          <img
            src="/logo.svg"
            alt="Light Conversion logo"
          >
          <br><br>
          <h3>Optical layout planner ${version}</h3>
          API version: ${this.apiVersionData}
          <br><br>
          <div style="text-align: left;">
          Optical table layout planner for scientific laser systems by Light Conversion. 
          <br>
          <small>  Copyright 2024 Light Conversion, UAB          </small>
          <br/><br/><br/>
          <h5>Support and contact</h5>
          <a href="http://www.lightcon.com/">www.lightcon.com</a> <br/>
          <a href="mailto:support@lightcon.com">support@lightcon.com</a>
          <br/>
          <br/>
          <h4>Legal disclaimer</h4>
          <br/>
          <small>Light Conversion, UAB and all associated materials are provided on an "as is" and "as available" basis without warranty of any kind, either express or implied. Light Conversion, UAB disclaims all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</small>
          </div>`,
      });
    },
  },
  mounted() {
    this.fetchApiVersion();
    EventBus.on("about", () => {
      this.showAbout();
    });

    $.ajax({
      url: "https://lightcon.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=f45f176d",
      type: "get",
      cache: true,
      dataType: "script",
    });

    window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: function (showCollectorDialog) {
        $("#feedback-button").click(function (e) {
          e.preventDefault();
          showCollectorDialog();
        });
      },
      fieldValues: {
        components: "10168", //toolbox component ID jira
      },
    };
  },
};
</script>
<style scoped>
a {
  color: whitesmoke;
}
a:hover {
  color: #cbcafc;
}
.dropdown-item {
  color: black;
  /*blanchedalmond*/
}
.show {
  color: #cbcafc !important;
}
.swal2-html-container {
  text-align: left !important; /* Užtikriname, kad šis stilius turėtų pirmumą */
}

</style>