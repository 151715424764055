export class EntityServices{
    getCanvasDto(canvas){ 
        var json = canvas.toJSON()
        return {
            version:json.version,
            objects:json.objects
        }
    }
}

export default EntityServices