export default class MagnificationServices {
    constructor(canvas) {
        this.canvas = canvas
        this.isPanning = false
        this.lastPosX = null
        this.lastPosY = null
        this.mag = false
        this.listeners = {
            scroll: this.zoomScrollListener.bind(this),
            context: this.contextListener.bind(this),
            mouseDown: this.mouseDownListener.bind(this),
            mouseMove: this.mouseMoveListener.bind(this),
            mouseUp: this.mouseUpListener.bind(this)
        }
    }
    mouseUpListener(){
        this.isPanning = false;
        this.lastPosX = null;
        this.lastPosY = null;
        this.canvas.selection = true;
    }
    mouseMoveListener(opt){
        if (this.isPanning && this.lastPosX && this.lastPosY) {
            const e = opt.e;
            const vpt = this.canvas.viewportTransform;
            vpt[4] += e.clientX - this.lastPosX;
            vpt[5] += e.clientY - this.lastPosY;
            this.canvas.requestRenderAll();
            this.lastPosX = e.clientX;
            this.lastPosY = e.clientY;
          }
    }
    mouseDownListener(e) {
        if (e.button === 2) {
            this.isPanning = true;
            this.lastPosX = e.clientX;
            this.lastPosY = e.clientY;
            this.canvas.selection = false;
        }

    }
    zoomScrollListener(opt) {
        var delta = opt.e.deltaY;
        var zoom = this.canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;
        this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
    }
    contextListener(e) {
        e.preventDefault();
    }
    enableMagnificationListeners() {
        this.canvas.on('mouse:wheel', this.listeners.scroll)
        this.canvas.upperCanvasEl.addEventListener("contextmenu", this.listeners.context);
        this.canvas.upperCanvasEl.addEventListener("mousedown", this.listeners.mouseDown);
        this.canvas.on('mouse:move',this.listeners.mouseMove)
        this.canvas.upperCanvasEl.addEventListener("mouseup",this.listeners.mouseUp)

    }
    disableMagnificationListeners() {
        this.canvas.off('mouse:wheel')
        this.canvas.upperCanvasEl.removeEventListener("contextmenu", this.listeners.context);
        this.canvas.upperCanvasEl.removeEventListener("mousedown", this.listeners.mouseDown)
        this.canvas.off('mouse:move')
        this.canvas.upperCanvasEl.removeEventListener("mouseup",this.listeners.mouseUp)
    }
}