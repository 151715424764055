import { fabric } from "fabric"
import store from '@/store/index'
import { propertiesExtender } from '@/components/helpers/Helpers'

class LayoutServices {

  constructor() {
    this.annotationTexts = [
      "Annotation",
    ];
  }
  print(){
    
  }
  annotate(layoutSettings, fill, layout) {
    layoutSettings.mode = "none";
    var newTextbox = new fabric.IText(
      this.annotationTexts[
      Math.floor(Math.random() * this.annotationTexts.length)
      ],
      {
        fill: fill, //this.$refs.anotationColorSelector.selectedColor,
        fontSize: 16,
        left: 50,
        top: 50,
        fontFamily: "Helvetica",
        evented: true,
        hasControls: true,
        visible: true,
        originY: "bottom",
        borderColor: layoutSettings.selectionColor,
        cornerColor: "",
        name:"annotation"
      }
    );
    layout.add(newTextbox).requestRenderAll();

    newTextbox
      .setControlsVisibility({
        bl: true,
        mb: false,
        br: true,
        ml: false,
        mr: false,
        mt: false,
        tl: true,
        tr: true,
        mtr: false,
      })
      .setCoords();
  }

  updateAnotationColor(color, applyToAll, layout) {
    if (applyToAll) {
      layout.forEachObject((obj) => {
        if (obj?.type === "i-text") {
          obj.fill = color;
          obj.dirty = true;
        }
      });
      layout.requestRenderAll();
      return;
    }

    var activeObject = layout.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        activeObject.forEachObject(function (obj) {
          if (obj?.type === "i-text") {
            obj.fill = color;
            obj.dirty = true;
          }
        });
      } else {
        if (activeObject?.type === "i-text") {
          activeObject.fill = color;
          activeObject.dirty = true;
        }
      }
    }
    layout.requestRenderAll();
  }
  updateBlackBoxColor(color,opacity, applyToAll, layout) {
    console.debug('updating');
    if (applyToAll) {
      layout.forEachObject((obj) => {
        if (obj?.name === "BLACK-BOX") {
          obj.getObjects()[0].set({"fill": color, "opacity": opacity})
          obj.getObjects()[0].dirty = true;
        }
      });
      layout.requestRenderAll();
      return;
    }

    var activeObject = layout.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        activeObject.forEachObject(function (obj) {
          if (obj?.name === "BLACK-BOX") {
            obj.getObjects()[0].set("fill", color)
            obj.getObjects()[0].dirty = true;
          }
        });
      } else {
        if (activeObject?.name === "BLACK-BOX") {
          activeObject.getObjects()[0].set("fill", color)
          activeObject.getObjects()[0].dirty = true;
        }
      }
    }
    layout.requestRenderAll();
  }

 
  loadFromJson(exportedJson, layout, layoutSettings) {
    return new Promise((resolve) => {
      layout.loadFromJSON(exportedJson, () => {
        layout.forEachObject((obj) => {
          console.debug('loading object: ', obj.name || obj.type);
          obj.set({
            borderColor: layoutSettings.selectionColor,
            cornerColor: '',
            cornerSize: 9,
            transparentCorners: false
          });
        })
        console.debug('all objectse loaded');
        resolve();
      })
    })
  }
  addGrid(layout, layoutSettings, widthLabel, heightLabel) {

    if (layout.getObjects().filter((obj) => { return obj.name === 'grid' }).length) {
      console.info('%ccanvas alredy have a grid element', 'color: #FF5733;')
      return
    }
    var arr = [];
    var radius = 1.5

    var border = new fabric.Rect({
      left: layoutSettings.canvasOffset,
      top: layoutSettings.canvasOffset,
      width: layoutSettings.width,
      height: layoutSettings.height,
      fill: 'rgba(150,150,150,1)',
      stroke: '#000',
      strokeWidth: 2,
      rx: 20,
      ry: 20
    })
    arr.push(border)


    for (let x = layoutSettings.gridOffset+layoutSettings.canvasOffset; x < layoutSettings.width+layoutSettings.canvasOffset; x += layoutSettings.gridSize) {
      for (let y = layoutSettings.gridOffset+layoutSettings.canvasOffset; y < layoutSettings.height+layoutSettings.canvasOffset; y += layoutSettings.gridSize) {
        const circle = new fabric.Circle({
          radius: radius,
          left: x - radius,
          top: y - radius,
          fill: "#000",
          selectable: false,
          evented: false,
          hoverCursor: "null",
        });
        arr.push(circle)
      }
    }

    var fontSize = layoutSettings.canvasLablesFontSize / layout.getZoom()
    console.debug('defined font size', fontSize)

    var widthText = new fabric.Text(widthLabel, {
      left: layoutSettings.width / 2 - layoutSettings.gridSize,
      top: layoutSettings.height  + layoutSettings.canvasOffset,
      fontSize: fontSize,
      fontFamily: "Helvetica",
      fill: '#999',
      name: 'width mark'
    });
    arr.push(widthText)

    var heightText = new fabric.Text(heightLabel, {
      left: layoutSettings.width + layoutSettings.canvasOffset,
      top: layoutSettings.height / 2 - layoutSettings.gridSize,
      fontSize: fontSize,
      fontFamily: "Helvetica",
      fill: '#999',
      name: 'width mark',
      angle: -90
    });
    arr.push(heightText)



    const gridGroup = new fabric.Group(arr, {
      name: "grid",
      selectable: false,
      evented: false,
      hoverCursor: "null",
      excludeFromExport: true
    })

    layout.add(gridGroup);
    layout.sendToBack(gridGroup);
    layout.requestRenderAll()
    console.debug("grid added")
  }
  defineZoom(containerWidth, containerHeight, layoutSettings) {
    var widthDiff = containerWidth / layoutSettings.width
    var heightDiff = containerHeight / layoutSettings.height
    var definedZoom = Math.min(widthDiff, heightDiff)
    console.debug('defined zoom: ', definedZoom)
    return definedZoom
  }
  addMarker(layout, obj) {
    if (!obj.isProduct) return
    console.debug('add marker on: ', obj.name);
    var svgString = `<svg xmlns="http://www.w3.org/2000/svg" height="10.5em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"/></svg>`
    fabric.loadSVGFromString(svgString, function (objects, options) {
      var svgObject = fabric.util.groupSVGElements(objects, options);
      svgObject.set({
        fill: "rgba(208, 239, 255,1)",
      });

      const desiredSize = obj.width < obj.height ? obj.width / 3 : obj.height / 3

      const scaleX = desiredSize / svgObject.width
      const scaleY = desiredSize / svgObject.height

      var group = new fabric.Group([svgObject], {
        left: obj.left + (obj.width / 2) - desiredSize / 2,
        top: obj.top + (obj.height / 2) - desiredSize / 2,
        selectable: false,
        name: 'freezeMarker',
        markerFor: obj.id,
        scaleX: scaleX,
        scaleY: scaleY,
        excludeFromExport: true
      })

      layout.add(group);
      layout.renderAll();
    });

  }
  disableSelection(layout) {
    var self = this
    layout.discardActiveObject().requestRenderAll()
    layout.forEachObject(function (obj) {
      if (obj.selectable) {
        obj.selectable = false;
        if (obj.name !== 'grid') {
          self.addMarker(layout, obj)
          obj.opacity = 0.3
        }
      }
    });
    console.debug("objects selection disabled")
    layout.renderAll();
  }
  disableSelectionSelected(layout) {
    var self = this
    var selected = layout.getActiveObjects()
    console.debug('selected: ', selected);
    if (selected) {
      selected.forEach((object) => {
        if (object.selectable) {
          object.selectable = false;
          if (object.name !== 'grid') {
            self.addMarker(layout, object)
            object.opacity = 0.3
          }
        }
      })

      layout.discardActiveObject().requestRenderAll()
    } else { console.info("%cNothisng selected", "color:red") }
  }
  enableSelection(layout) {
    console.debug("enabling objects")
    layout.forEachObject(function (obj) {
      if (obj?.name === 'grid' || obj?.name === 'freezeMarker') { return }
      obj.selectable = true;
      obj.opacity = 1
      var marker = layout.getObjects().find((objj) => {
        return objj?.name === "freezeMarker" && objj?.markerFor === obj.id
      })

      if (marker) {
        console.debug('remove marker from: ', obj.name);
        layout.remove(marker)
      }
    });
    layout.requestRenderAll()
  }
  removeGrid(name, layout) {
    console.debug('removing: ', name);
    var objects = layout.getObjects()
    if (!objects) return
    var grid = objects.find(obj => obj.name === name)
    if (!grid) return
    layout.remove(grid);


  }
  hideObj(name, layout) {
    if (name == '') name = 'grid'
    if (this.removed == null) {
      var grid = layout.getObjects().find(obj => obj.name === name)
      this.removed = grid;
      layout.remove(grid);
      console.debug('hiding: ', name);
    } else {
      layout.add(this.removed);
      layout.sendToBack(this.removed);
      this.removed = null
      console.debug('show: ', name);
    }
    layout.requestRenderAll()
  }

  sendToBack(layout) {
    if (layout.getActiveObject()) {
      layout.getActiveObject().sendToBack();

      layout.getObjects().filter(function (obj) { return obj.name === "grid"; })[0].sendToBack()
    }
    layout.requestRenderAll();
    console.debug("object sent to back")
  }
  sendToFront(layout) {
    if (layout.getActiveObject()) {
      layout.getActiveObject().bringToFront();
    }
    layout.requestRenderAll();
    console.debug("object brought to front")
  }
  downloadAsPng(layout) {
    var dataURL = layout.toDataURL({ format: 'png', quality: 1.0 });

    var link = document.createElement('a');
    link.href = dataURL;
    link.download = `${layout.name || 'scheme'}.png`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.info("downloaded as png")
  }
  setRotationZero(layout) {
    var activeObject = layout.getActiveObject();
    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        activeObject.forEachObject(function (obj) {
          obj.rotate(0);
        });
      } else {
        activeObject.rotate(0);
      }
      layout.requestRenderAll();
    }
  }
  rotate(angle, layout) {
    var activeObject = layout.getActiveObject()
    if (!activeObject) {
      console.info('%cno items selected', 'color: #FF5733;')
      return
    }
    

    if (activeObject) {
      if (activeObject.type === "activeSelection") {
        activeObject.forEachObject(function (obj) {
          obj.rotate((obj.angle + angle) % 360).setCoords();
        });
      } else {
        activeObject.rotate((activeObject.angle + angle) % 360).setCoords()
        let snpx = activeObject.snapX;
        let snpy = activeObject.snapY;
        activeObject.snapX = snpy
        activeObject.snapY = snpx
        console.log('obj: ', activeObject.snapX, activeObject.snapY, activeObject);
      }
    }

      
    layout.requestRenderAll()
    console.debug(activeObject?.name, " rotated to ", activeObject.angle, " angle")
  }
  deleteActiveItem(layout) {
    var obj = layout.getActiveObject()
    if (!obj) {
      console.info('%cno items selected', 'color: #FF5733;')
      return
    }
    layout.remove(obj)
    layout.requestRenderAll()
    console.info('%cremoved ' + obj.name, 'color: #FF5733;')
  }
  copyTolipBoard(activeObject) {
    if (activeObject) {
      var objectsToCopy;
      if (activeObject.type === 'activeSelection') {
        objectsToCopy = activeObject.getObjects().map(o => {
          var objClone = fabric.util.object.clone(o);
          
          objClone.set({
            left: objClone.left+activeObject.ownMatrixCache.value[4],
            top: objClone.top+activeObject.ownMatrixCache.value[5]
          });
          return objClone.toObject();
        });
      } else {
        var objClone = fabric.util.object.clone(activeObject);
        objectsToCopy = [objClone.toObject()];
      }

      var copiedText = JSON.stringify(objectsToCopy);
      navigator.clipboard.writeText(copiedText).then(function () {
        console.info('Objects copied to clipboard.');
      }).catch(function (error) {
        console.error('Error while copying objects: ', error);
      });
    }
  }
  pasteFromClipBoard(layout) {
    navigator.clipboard.readText().then(function (pastedText) {
      try {
        var objectsToPaste = JSON.parse(pastedText);
        fabric.util.enlivenObjects(objectsToPaste, function (enlivenedObjects) {
          enlivenedObjects.forEach(function (obj) {
            layout.add(obj);
            propertiesExtender([obj])
          });
          layout.renderAll();
        });
      } catch (error) {
        console.error('Error while pasting: ', error);
      }
    }).catch(function (error) {
      console.error('Error while retrieving data from clipboard: ', error);
    });
    layout.requestRenderAll()
  }
  keyDownListener(layout, options) {
    var layoutSettings = store.state.layoutSettings

    var activeObject = layout.getActiveObject();
    
    var gridSize = layoutSettings.gridSize;
    var key = options.which || options.keyCode;
    switch (key) {
      case 67://ctrl+c
        if (options.ctrlKey) {
          this.copyTolipBoard(activeObject)
        }

        break;
      case 86://ctrl+v
        if (options.ctrlKey) {
          this.pasteFromClipBoard(layout)
        }
        break;
      case 38:
        console.debug("%cup button pressed", "color: red;");
        if (activeObject) {
          if (activeObject.type === "activeSelection") {
            activeObject.forEachObject(function (obj) {
              obj.set("top", obj.top - gridSize);
            });
          } else {
            activeObject.set("top", activeObject.top - gridSize);
          }
          layout.requestRenderAll();
        } else {
          if (options.ctrlKey)
          layoutSettings.height -= gridSize;
        }
        break;
      case 40:
        console.debug("%cdown button pressed", "color: red;");
        if (activeObject) {
          if (activeObject.type === "activeSelection") {
            activeObject.forEachObject(function (obj) {
              obj.set("top", obj.top + gridSize);
            });
          } else {
            activeObject.set("top", activeObject.top + gridSize);
          }
          layout.requestRenderAll();
        } else {
          if (options.ctrlKey)
          layoutSettings.height += gridSize;
        }
        break;
      case 39:
        console.debug("%cright button pressed", "color: red;");
        if (activeObject) {
          if (activeObject.type === "activeSelection") {
            activeObject.forEachObject(function (obj) {
              obj.set("left", obj.left + gridSize);
            });
          } else {
            activeObject.set("left", activeObject.left + gridSize);
          }
          layout.requestRenderAll();
        } else {
          if (options.ctrlKey)
          layoutSettings.width += gridSize;
        }
        break;
      case 37:
        console.debug("%left button pressed", "color: red;");
        if (activeObject) {
          if (activeObject.type === "activeSelection") {
            activeObject.forEachObject(function (obj) {
              obj.set("left", obj.left - gridSize);
            });
          } else {
            activeObject.set("left", activeObject.left - gridSize);
          }
          layout.requestRenderAll();
        } else {
          if (options.ctrlKey)
          layoutSettings.width -= gridSize;
        }
        break;
      case 46:
        if (!activeObject) {
          return;
        }

        if (activeObject?.type === "i-text") {
          var text = activeObject.text;
          var selectionStart = activeObject.selectionStart;

          if (selectionStart < text.length) {
            const updatedText =
              text.slice(0, selectionStart) + text.slice(selectionStart + 1);
            activeObject.set("text", updatedText);
          }
        } else {
          if (activeObject.type === "activeSelection") {
            activeObject.forEachObject(function (obj) {
              layout.remove(obj);
            });
          } else {
            layout.remove(activeObject);
          }
          layout.requestRenderAll();
        }
    }
  }

  enableLayoutListeners(layout, productServices
    //, layoutSettings
  ) {
    console.debug('enabling layout listeners')
    fabric.util.addListener(document.body, "keydown", (event) => this.keyDownListener(layout, event));
    layout.on({
      'mouse:down': function (e) {
        if (e.target) {
          e.target.opacity = 0.7;
          layout.requestRenderAll();
        }
      },
      'mouse:up': function (e) {
        if (e.target) {
          e.target.opacity = 1;
          layout.requestRenderAll();
        }
      },
      'object:moved': function (e) {
        e.target.opacity = 0.7;
      },
      'object:modified': function (e) {
        e.target.opacity = 1;
      },
      'object:scaling': function () {
      },
      'object:moving': function (options) {
        productServices.align(options.target);
      }
    });
  }
  disableLayoutListeners(layout, productServices) {
    console.debug("disabling layout listeners")
    layout.off({
      'mouse:down': function (e) {
        if (e.target) {
          e.target.opacity = 0.7;
          layout.requestRenderAll();
        }
      },
      'mouse:up': function (e) {
        if (e.target) {
          e.target.opacity = 1;
          layout.requestRenderAll();
        }
      },
      'object:moved': function (e) {
        e.target.opacity = 0.7;
      },
      'object:modified': function (e) {
        e.target.opacity = 1;
      },
      'object:scaling': function () {

      },
      'object:moving': function (options) {
        console.debug('moving: ', options.target.name);
        productServices.align(options.target);
      }
    });
  }
}

export default LayoutServices;