export default {
    namespaced: false,
    state() {
        return {
            name:'',
            width: 1200,
            height: 800,
            zoom: 1,
            removed: null,
            mode: "none",
            selectionColor: "red",
            gridSize: 25,
            gridOffset: 25,

            canvasLablesFontSize: 48,

            canvasOffset:25,
            keysPressed: {
                shift: false,
                ctrl: false,
            },
            imperialUnits: false,
            mmToFt: 304.8,
            layoutMetaData: {
                author: "",
                comments: "",
                email: "",
                notificationSent: true,
                title: "",
            },
            freezed:false
        };
    },
    getters: {
        layoutSettings(state) {
            return state;
        },
        metaData(state) {
            return state.layoutMetaData;
        },
        widthLabel(state) {
            return state.imperialUnits ? `${parseFloat((state.width / state.mmToFt).toFixed(2))}ft` : `${state.width}mm`;

        },
        heightLabel(state) {
            return state.imperialUnits ? `${parseFloat((state.height / state.mmToFt).toFixed(2))}ft` : `${state.height}mm`;
        },
        freezed(state){
            return state.freezed;
        }
    },
    mutations: {
        setSettings(state, newSettings) {
            state = newSettings
        },
        SET_SCHEME_NAME(state, name){
            state.name = name
        },
        SET_FREEZED(state, condition){
            state.freezed = condition
        }
    }
}