import { createStore } from 'vuex';
import layoutSettings from './layoutSettings';
import products from './products'
import history from './history'
export default createStore({
  state() {
    return {
      isLoading:false
    }
  },
  modules:{
    layoutSettings,
    products,
    history
  },
  mutations: {
    SET_ISLOADING(state, condition){
      state.isLoading = condition
    }
  },
  actions: {
    // čia įdėkite savo veiksmus
  },
  getters: {
    isLoading(state){
      return state.isLoading
    }
  }
});
