import axios from "axios"

export default {
    namspaced: false,
    state() {
        return {
            products: [],
            selected: {},
            selection:{}
        }
    },
    getters: {
        selection(state){
            return state.selection},
        selectedProduct(state) {
            return state.selected;
        },
        products(state) {
            return state.products
        },
        categorizedProducts: (state) => {
            let result = state.products.reduce((acc, product) => {

                if (!acc[product.category]) {
                    acc[product.category] = [];
                }
                acc[product.category].push(product);

                return acc;
            }, {});

            return Object.keys(result).map(category => {
                return {
                    category,
                    list: result[category]
                };
            });
        },
        blackBoxDefaultSizes(state) {
            var bb = state.products.find((product) => {
                return product.name === 'BLACK-BOX'
            })
            return { width: bb.width || 0, height: bb.height || 0 }
        },
        idFromName:(state)=>(name)=>{
            return state.products.find((product)=>{return product.name == name})?.id
        }
    },
    mutations: {
        SET_PRODUCTS(state, newProductList) {
            state.products = newProductList
        },
        SET_SELECTED_PRODUCT(state, selected) {
            state.selected = selected
        },
        UPDATE_PRODUCT(state, updatedProduct) {
            const index = state.products.findIndex(p => p.id === updatedProduct.id);
            if (index !== -1) {
                state.products.splice(index, 1, updatedProduct);
            }
        },
        SET_SELECTION(state, selection){
            state.selection = selection
        }
    },
    actions: {
        product({ state }, id) {
            return state.products.find(p => p.id === id)
        },
        async fetchProducts({ commit }) {
            try {
                const response = await axios.get(process.env.VUE_APP_PRODUCT_LIST);
                commit('SET_PRODUCTS', response.data);
                console.debug('loaded ', response.data.length, 'products');
            } catch (error) {
                console.error('Store error: ', error);
            }
        },
        async updateSelectedProduct({ dispatch, commit, state }, selectedProduct) {

            commit('SET_SELECTION', selectedProduct);

            if (!selectedProduct?.id) {
                state.selected = null;
                return
            }

            try {
                await dispatch('updateProduct3DAvailability', selectedProduct)
            } catch (error) {
                console.error('Erro when updating selected product: ', error);
            }
            finally {
                commit('SET_SELECTED_PRODUCT', state.products.find(p => p.id === selectedProduct.id))
            }
        },
        async updateProduct3DAvailability({ commit, state }, selectedProduct) {
            const product = state.products.find(p => p.id === selectedProduct.id);

            if (!product || product.is3DAvailable !== undefined || product.isStpAvailable !== undefined)
                return selectedProduct;

            try {
                const objResponse = await axios.head(`${process.env.VUE_APP_3D_PATH}OBJ/${selectedProduct.name}/${selectedProduct.name}.obj`)
                const mtlResponse = await axios.head(`${process.env.VUE_APP_3D_PATH}OBJ/${selectedProduct.name}/${selectedProduct.name}.mtl`)
                product.is3DAvailable = objResponse.status === 200 && mtlResponse.status === 200
                const stpResponse = await axios.head(`${process.env.VUE_APP_3D_PATH}OBJ/${selectedProduct.name}/${selectedProduct.name}.stp`)
                product.isStpAvailable = stpResponse.status === 200
            } catch (error) {
                console.error("Store error when updating 3d status: ", error)
                product.is3DAvailable = false
            }
            try {
                const stpResponse = await axios.head(`${process.env.VUE_APP_3D_PATH}OBJ/${selectedProduct.name}/${selectedProduct.name}.stp`)
                product.isStpAvailable = stpResponse.status === 200
            } catch (error) {
                console.error("Store error when updating stp status: ", error)
                product.is3DAvailable = false
            }
            commit('UPDATE_PRODUCT', product)
        }
    }
}