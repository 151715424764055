<template>
  <div id="content">
    <table>
      <tr>
        <td align="left">
          <a :href="link"
            >{{getLinkToScheme()}}</a
          >
        </td>
        <td align="right">
          <button
              data-bs-dismiss="modal"
            >
              Close
            </button>
          <button @click="print()">Print</button>
        </td>
      </tr>
    </table>
  </div>

  <table>
    <tr>
      <td align="left">
        <img
          src="http://lightcon.com/images/LCN-logo.png"
          alt="Light Conversion logo"
        />
      </td>
      <td align="right" />
    </tr>
  </table>

  <table>
    <tr>
      <td>
        <h1><strong>Optical table layout</strong></h1>
        <h2>
          <strong>{{ pdfData?.layoutSettings?.title }}</strong>
        </h2>
        <p>{{ pdfData?.layoutSettings?.comments }}</p>
        <table class="table table-striped">
          <thead />
          <tr>
            <td><strong>Author</strong></td>
            <td>{{ pdfData?.layoutSettings?.author }}</td>
          </tr>
          <tr>
            <td><strong>Email</strong></td>
            <td>{{ pdfData?.layoutSettings?.email }}</td>
          </tr>
          <tr>
            <td><strong>Date</strong></td>
            <td>{{ today }}</td>
          </tr>
          <tr>
            <td><strong>Layout code</strong></td>
            <td>{{ pdfData?.name }}</td>
          </tr>
          <tr>
            <td><strong>Table size</strong></td>
            <td>{{ tableSizeFromParameters(pdfData?.tableParameters) }}</td>
          </tr>
          <tr>
            <td><strong>Table type</strong></td>
            <td>{{ pdfData?.tableParameters?.type }}</td>
          </tr>
        </table>
      </td>
      <td valign="bottom" align="right">
        <img :src="qrsrc" alt="" title="" />&nbsp;&nbsp;&nbsp;
      </td>
    </tr>
  </table>

  <div style="margin-top: 10px">
    <img id="layoutImg" :src="image" />
  </div>

  <div id="kitchenWrap">
    <div>
      <h2>Items</h2>
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Item name</th>
            <th>Item code</th>
            <th>Full size</th>
            <th>Housing size</th>
            <th>Dynamics NAV code</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(items, index) in pdfData?.products" :key="index">
            <td v-for="item in items" :key="item">
              {{ item }}
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table width="880" border="0">
        <tr>
          <td align="left">© Light Conversion, UAB</td>
          <td align="right">
            Phone: +370 5 249 18 30,&nbsp; e-mail:&nbsp;<a
              href="mailto:sales@lightcon.com"
              >sales@lightcon.com</a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import SchemesServices from "../Schemes/SchemesServices";
export default {
  props: {
    id: String,
    metadata: Object,
    layout: Object,
  },
  data() {
    return {
      pdfData: null,
    };
  },
  computed: {
    link() {
      if (this.pdfData != null) {
        return "/scheme/" + this.id;
      } else {
        return "/scheme/";
      }
    },
    qrsrc() {
      if (this.pdfData != null) {
        return (
          "https://api.qrserver.com/v1/create-qr-code/?data=http://toolbox.lightcon.com/planner/" +
          this.pdfData.name +
          "&size=150x150"
        );
      } else {
        return "";
      }
    },
    today() {
      var today = new Date();
      var hh = today.getHours();
      var min = today.getMinutes();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = "0" + dd;
      }

      if (mm < 10) {
        mm = "0" + mm;
      }

      if (min < 10) {
        min = "0" + min;
      }

      return yyyy + "-" + mm + "-" + dd + " " + hh + ":" + min;
    },
    image() {
      return (
        process.env.VUE_APP_SCHEMES_PATH +
        this.id +
        ".png" +
        "?" +
        Date.now()
      );
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getLinkToScheme(){
      return `${process.env.VUE_APP_BASE_APP_URL}scheme/${this.pdfData?.name}`
    },
    print() {
      window.print();
    },
    async getData() {
      try {
        var url =
          process.env.VUE_APP_API_BASE_URL +
          process.env.VUE_APP_API_GET_SCHEME +
          this.id;
        const response = await fetch(url);
        const scheme = await response.text();

        var parsedData = JSON.parse(scheme);
        this.pdfData = SchemesServices.unifyPropertiesNames(parsedData);
        // this.pdfData = SchemesServices.unifyPropertiesNames(JSON.parse(scheme));
      } catch (error) {
        console.error("error: ",error);
      }
    },
    tableSizeFromParameters(tableParameters) {
      if (tableParameters?.type === "imperial") {
        return (
          tableParameters?.x / 30.48 / 10 +
          " ft x " +
          tableParameters?.y / 30.48 / 10 +
          " ft"
        );
      } else {
        return (
          tableParameters?.x / 10 + " cm x " + tableParameters?.y / 10 + " cm"
        );
      }
    },
  },
};
</script>
<style lang="css" scoped>
h1,
h2 {
  color: black;
}
#PrintModal {
  backface-visibility: visible;
  background-color: white;
}
img {
  width: 200px;
}
table {
  width: 100%;
  border: 0;
}
#layoutImg {
  height: 900px;
  width: auto;
}
</style>
