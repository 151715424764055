import { fabric } from "fabric";
//Singletone
export class MeasureServices {
    static instance
    constructor(layoutSettings, layout, layoutServices) {
        if(MeasureServices.instance){return MeasureServices.instance}
        this.layoutSettings = layoutSettings;
        this.layout = layout;
        this.layoutServices = layoutServices;
        this.stage = 0;
        this.lineWidth = 1.5;
        this.lineColor = '#000';
        this.fontSize = 25;
        this.fontFamily = "Helvetica";
        this.isNotFirstTuch = false;
        this.dimension = "";
        this.widthText0 = '0'
        this.listeners = {
            mouseMove: this.mouseMoveListener.bind(this),
            mouseDown: this.mouseDownListener.bind(this),
            // keyDown: this.keyDownListener.bind(this),
            // keyUp: this.keyUpListener.bind(this)
        }
        MeasureServices.instance = this
        return MeasureServices.instance
    }
    static getInstance(layoutSettings, layout, layoutServices) {
        if (this.instance) {
            if (layoutSettings || layout || layoutServices) {
                console.warn('Instance already exists. Provided parameters will be ignored.');
            }
            return this.instance;
        }

        if (!layoutSettings || !layout || !layoutServices) {
            throw new Error('Please provide all necessary parameters to create a Measure services instance.');
        }

        this.instance = new MeasureServices(layoutSettings, layout, layoutServices);
        return this.instance;
    }
    
    mouseDownListener(options){
        if (this.layoutSettings.mode === "measuring")
            this.mouseDown(options);
    }
    mouseMoveListener(options){
        if (this.layoutSettings.mode === "measuring") {
            this.mouseMove(options);
        }
    }
    enableListeners() {
        this.layout.on("mouse:move", this.listeners.mouseMove);
        this.layout.on("mouse:down", this.listeners.mouseDown);
        console.debug("measuring listeners enabled");
    }
    disableListeners() {
        this.layout.off("mouse:move", this.listeners.mouseMove);
        this.layout.off("mouse:down", this.listeners.mouseDown);
        console.debug("measuring listeners disabled");
    }
    createLine(points, name) {
        console.debug("creating line");
        return new fabric.Line(points, {
            hasControls: false,
            name: name,
            strokeWidth: this.lineWidth,
            fill: this.lineColor,
            stroke: this.lineColor,
            originX: "center",
            originY: "center",
            selectable: true,
            noScaleCache: true
        });
    }
    mouseDown(options) {
        var pointer = this.layout.getPointer(options.e);
        var points = [pointer.x, pointer.y, pointer.x, pointer.y];
        switch (this.stage) {
            case 0:
                this.line0 = this.createLine(points, "measure_" + this.dimension + this.stage);
                this.layout.add(this.line0);
                this.widthText0 = new fabric.Text('0', {
                    fontSize: Math.floor(23 / this.layoutSettings.zoom),
                    fontFamily: this.fontFamily,
                    fill: this.lineColor,
                    left: (this.line0.x1 + this.line0.x2) / 2,
                    top: pointer.y,
                    styles: "",
                    originX: "center",
                });
                this.layout.add(this.widthText0);
                this.widthText0.toObject = (function (toObject) {
                    return function () {
                        return fabric.util.object.extend(toObject.call(this), {
                            styles: "",
                        });
                    };
                })(this.widthText0.toObject);
                this.stage++;
                return;
            case 1:
                this.line1 = this.createLine(points, "measure_" + this.dimension + this.stage);
                this.layout.add(this.line1);
                this.layout.remove(this.widthText0);
                this.stage++;
                return;

            case 2:
                if (this.dimension === "width") {
                    points = [this.line0.x2, pointer.y, this.line1.x2, pointer.y];
                    var width = Math.floor(Math.abs(this.line0.x1 - this.line1.x1 - 1));
                } else {
                    points = [pointer.x, this.line0.y2, pointer.x, this.line1.y2];
                    width = Math.floor(Math.abs(this.line0.y1 - this.line1.y1 - 1));
                }

                this.line2 = this.createLine(points, "measure_" + this.dimension + this.stage);
                this.layout.add(this.line2);

                var slash1 = this.createLine([points[0] - 5, points[1] + 5, points[0] + 6, points[1] - 6], "");
                var slash2 = this.createLine([points[2] - 5, points[3] + 5, points[2] + 6, points[3] - 6], "");

                this.layout.add(slash1);
                this.layout.add(slash2);

                this.widthText = new fabric.Text(width.toString(), {
                    fontSize: Math.floor(23 / this.layoutSettings.zoom),
                    fontFamily: this.fontFamily,
                    fill: this.lineColor,
                    left: (this.line0.x2 + this.line1.x2) / 2,
                    top: pointer.y,
                    styles: "",
                });

                this.widthText.set({
                    originX: "center",
                });

                if (this.dimension === "width") {
                    if (this.line0.y1 > this.line0.y2) {
                        this.widthText.set({ originY: "bottom" });
                    } else {
                        this.widthText.set({ originY: "top" });
                    }

                    this.widthText.set({
                        left: (this.line0.x2 + this.line1.x2) / 2,
                        top: pointer.y,
                    });
                } else {
                    if (this.line0.x1 < this.line0.x2) {
                        this.widthText.set({ originY: "bottom" });
                    } else {
                        this.widthText.set({ originY: "top" });
                    }

                    this.widthText.set({
                        left: pointer.x,
                        top: (this.line0.y2 + this.line1.y2) / 2,
                        angle: 90,
                        name:'rulersWidthText'
                    });
                }

                this.layout.add(this.widthText);

                this.widthText.toObject = (function (toObject) {
                    return function () {
                        return fabric.util.object.extend(toObject.call(this), {
                            styles: "",
                            
                        });
                    };
                })(this.widthText.toObject);

                this.layout.ruler = new fabric.Group(
                    [
                        this.line0,
                        this.line1,
                        this.line2,
                        this.widthText,
                        slash1,
                        slash2,
                    ],
                    {
                        hasControls: false,
                    }
                );

                this.layout.ruler.name = "ruler";

                this.layout.ruler.toObject = (function (toObject) {
                    return function () {
                        return fabric.util.object.extend(toObject.call(this), {
                            name: this.name,
                            
                        });
                    };
                })(this.layout.ruler.toObject);

                this.layout.add(this.layout.ruler);

                this.layout.remove(this.line0);
                this.layout.remove(this.line1);
                this.layout.remove(this.line2);
                this.layout.remove(this.widthText);
                this.layout.remove(slash1);
                this.layout.remove(slash2);

                this.line0 = this.line1 = this.line2 = undefined;
                this.layoutSettings.mode = 'none';
                this.stage = 0;

                this.layoutServices.enableSelection(this.layout);
                this.disableListeners();

                return;
        }
    }
    drawMiddleLine(pointer) {
        var points = [pointer.x, pointer.y, pointer.x, pointer.y];
        if (typeof this.line2 === "undefined") {
            this.line2 = new fabric.Line(points, {
                hasControls: false,
                name: "measure_" + this.dimension + this.stage,
                strokeWidth: this.lineWidth,
                fill: this.lineColor,
                stroke: this.lineColor,
                originX: "center",
                originY: "center",
                selectable: true,
                targetFindTolerance: true,
            });
            this.layout.add(this.line2);
        } else {
            this.line2
                .set({
                    x1: this.line0.x2,
                    y1: this.line0.y2,
                    x2: pointer.x,
                    y2: pointer.y,
                })
                .setCoords();
        }
        console.debug("middle line added");
    }
    isBetween(x, a, b) {
        if ((a < x && x < b) || (b < x && x < a)) {
            return true;
        } else {
            return false;
        }
    }
    mouseMove(options) {
        var pointer = this.layout.getPointer(options.e);
        var width;

        switch (this.stage) {
            case 0:
                break;
            case 1:
                if (Math.abs(pointer.x - this.line0.x1) > Math.abs(pointer.y - this.line0.y1)) {
                    this.dimension = "height";
                    width = Math.floor(Math.abs(this.line0.x1 - this.line0.x2));
                    this.line0
                        .set({
                            x2: pointer.x,
                            y2: this.line0.y1,
                        })
                        .setCoords();
                } else {
                    this.dimension = "width";
                    width = Math.floor(Math.abs(this.line0.y1 - this.line0.y2));
                    this.line0
                        .set({
                            x2: this.line0.x1,
                            y2: pointer.y,
                        })
                        .setCoords();
                }
                this.widthText0.set({
                    text: width.toString(),
                });

                if (this.dimension === "width") {
                    if (this.line0.y1 > this.line0.y2) {
                        this.widthText0.set({ originY: "bottom" });
                    } else {
                        this.widthText0.set({ originY: "top" });
                    }

                    this.widthText0.set({
                        top: (this.line0.y1 + this.line0.y2) / 2,
                        left: this.line0.x1,
                        angle: 90,
                        fill: this.lineColor,
                    });
                } else {
                    if (this.line0.x1 < this.line0.x2) {
                        this.widthText0.set({ originY: "bottom" });
                    } else {
                        this.widthText0.set({ originY: "top" });
                    }

                    this.widthText0.set({
                        top: this.line0.y1,
                        left: (this.line0.x1 + this.line0.x2) / 2,
                        angle: 0,
                        fill: this.lineColor,
                    });
                }

                this.layout.requestRenderAll();
                break;
            case 2:
                if (this.isBetween(pointer.x, this.line0.x1, this.line1.x1)) {
                    this.dimension = "width";
                    this.line0
                        .set({
                            x2: this.line0.x1,
                            y2: pointer.y + this.lineWidth / 2,
                        })
                        .setCoords();
                    this.line1
                        .set({
                            x2: this.line1.x1,
                            y2: pointer.y + this.lineWidth / 2,
                        })
                        .setCoords();
                } else {
                    this.dimension = "height";
                    // if (isBetween (pointer.y, KITCHEN.measure.line0.y1, KITCHEN.measure.line1.y1)) {
                    this.line0
                        .set({
                            x2: pointer.x + this.lineWidth / 2,
                            y2: this.line0.y1,
                        })
                        .setCoords();
                    this.line1
                        .set({
                            x2: pointer.x + this.lineWidth / 2,
                            y2: this.line1.y1,
                        })
                        .setCoords();
                    // }
                }
                this.layout.requestRenderAll();
                break;
        }
    }
}
