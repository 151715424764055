<template>
  <div class="panel panel-default">
    <div id="product_annotation" class="panel-body">
      <h4>{{ product.longName }}</h4>
      <p>{{ product.descriptionText }}</p>
      <img class="img-responsive" :src="imgUrl" />
      <a :href="product.descriptionLink" target="_blank">More...</a>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    product: null,
  },
  computed: {
    imgUrl() {
      if (this.product.descriptionImage == null) return null;

      return (
        process.env.VUE_APP_INFO_PATH +
        this.product.descriptionImage +
        "?" +
        Date.now()
      );
    },
  },
};
</script>
  <style>
/* #product_annotation {
  max-width: 100px;
} */
.img-responsive {
    max-width: 80%;
    height: auto;
}
</style>